// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import PostThumbnail from '../PostThumbnail';

// Styles.
import './PostGrid.scss';

const PostGrid = ({ posts }) => (
    <div className="PostGrid">
        { posts.map((post, key) => <PostThumbnail data={ post } key={ key } />) }
    </div>
);

PostGrid.propTypes = {
    posts: PropTypes.array,
};

PostGrid.defaultProps = {
    posts: [],
};

export default PostGrid;
