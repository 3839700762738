// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash.debounce';

// Components.
import SearchDropdownResults from './elements/SearchDropdownResults';
import SearchIcon from '../SearchIcon';

// API.
import { searchAll } from '../../api/search';

// Utils.
import { convertToRelativeURL } from '../../utils/menu';

// Styles.
import './SearchDropdown.scss';

class SearchDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searching: false,
            searchValue: '',
        };

        this.handleSearchDebounced = debounce(this.handleSearch, 1000);
    }

    handleSearch = () => {
        const { storeSearchResults } = this.props;
        const { searchValue } = this.state;

        this.setState({ searching: true },
            async () => {
                const results = await searchAll({ search: searchValue });
                storeSearchResults(results);
                this.setState({ searching: false });
            }
        );
    };

    handleValueChange = event => {
        const { storeSearchValue } = this.props;

        storeSearchValue(event.target.value);
        this.setState({ searchValue: event.target.value }, this.handleSearchDebounced);
    };

    handleSubmit = event => {
        const { gvars, history } = this.props;
        event.preventDefault();
        history.push(convertToRelativeURL(gvars.search_results_page));
    };

    render() {
        const { hovered, results, updateHoverState } = this.props;
        const { searching, searchValue } = this.state;

        const classes = classnames({
            'SearchDropdown': true,
            'visible': hovered,
            'disabled': !searchValue,
        });

        return (
            <div
                className={ classes }
                onMouseEnter={ () => { updateHoverState(true) } }
                onMouseLeave={ () => { updateHoverState(false) } }
            >
                <form onSubmit={ this.handleSubmit }>
                    <div className="search-input">
                        <SearchIcon />
                        <input
                            type="text"
                            value={ searchValue }
                            placeholder="Begin Searching..."
                            onChange={ this.handleValueChange }
                            required
                        />
                        <button className="submitter">→</button>
                    </div>
                </form>

                { (searching || results) &&
                    <div className="search-results">
                        { searching
                            ? <div className="h8">Searching...</div>
                            : <SearchDropdownResults results={ results } />
                        }
                    </div>
                }
            </div>
        );
    }

};

SearchDropdown.propTypes = {
    gvars: PropTypes.object,
    history: PropTypes.object.isRequired,
    hovered: PropTypes.bool.isRequired,
    storeSearchResults: PropTypes.func.isRequired,
    storeSearchValue: PropTypes.func.isRequired,
    updateHoverState: PropTypes.func.isRequired,
    results: PropTypes.array,
};

SearchDropdown.defaultProps = {
    gvars: {},
    results: null,
};

export default SearchDropdown;
