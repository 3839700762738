// Modules.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import SearchDropdown from '../../../SearchDropdown';
import SearchIcon from '../../../SearchIcon';

// Styles.
import './SearchMenuItem.scss';

const SearchMenuItem = ({ collapsed, gvars }) => {

    const [ hovered, setHovered ] = useState(false);

    const classes = classnames({
        'SearchMenuItem': true,
        'collapsed': collapsed,
        'hovered': hovered,
    });

    return (
        <div className={ classes }>
            <h5
                onMouseEnter={ () => { setHovered(true) } }
                onMouseLeave={ () => { setHovered(false) } }
            >
                {/* Double-titles for hover-state */}
                <span><SearchIcon /> { gvars.search_menu_text }</span>
                <span><SearchIcon /> { gvars.search_menu_text }</span>
            </h5>

            <SearchDropdown updateHoverState={ setHovered } hovered={ hovered } />
        </div>
    );

};

SearchMenuItem.propTypes = {
    collapsed: PropTypes.bool,
    gvars: PropTypes.object,
};

SearchMenuItem.defaultProps = {
    collapsed: false,
    gvars: {},
};

export default SearchMenuItem;
