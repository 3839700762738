// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import DangerousCopy from '../DangerousCopy/DangerousCopy';

// Styles.
import './AlignedCopyList.scss';

const AlignedCopyList = ({ data }) => (
    <div className="AlignedCopyList">
        <div className="wrapper">
            { (data.header || data.header_copy) &&
                <div className={ classnames({
                    'header-container': true,
                    [data.header_alignment]: data.header_alignment,
                }) }>
                    { data.header && <h1>{ data.header }</h1> }
                    { data.header_copy && <p>{ data.header_copy }</p> }
                </div>
            }

            { data.copy_list_item &&
                <div className={ classnames({
                    'copy-container': true,
                    'line-between': data.separator === 'line_bet',
                    'line-per-item': data.separator === 'line_per',
                }) }>
                    { data.copy_list_item.map((copyData, key) => (
                        <div className="copy-wrapper" key={ key }>
                            <DangerousCopy copy={ copyData.copy } />
                        </div>
                    )) }
                </div>
            }
        </div>
    </div>
);

AlignedCopyList.propTypes = {
    data: PropTypes.object.isRequired,
};

export default AlignedCopyList;
