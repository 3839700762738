// Modules.
import { connect } from 'react-redux';

// Components.
import NewsSection from './NewsSection';

// Store.
import { updatePageLoadCounter } from '../../redux/actions/app';

const mapDispatchToProps = {
    updatePageLoadCounter,
};

export default connect(null, mapDispatchToProps)(NewsSection);
