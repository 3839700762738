// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import './PostThumbnail.scss';

const PostThumbnailStyle = {
    COLOR: 'color',
    IMAGE: 'image',
    QUOTE: 'quote',
};

const PostThumbnail = ({ data }) => {

    const post_style = data.acf.grid_thumbnail_style;
    const bg_image = (data.acf.grid_thumbnail_image && data.acf.grid_thumbnail_image.sizes) ? data.acf.grid_thumbnail_image.sizes.large : '';
    const bg_color = data.acf.grid_thumbnail_background_color;
    const quote = data.acf.grid_thumbnail_quote;

    const classes = classnames({
        'PostThumbnail': true,
        [bg_color]: post_style === PostThumbnailStyle.COLOR,
    });

    const style = {};
    if (post_style === PostThumbnailStyle.IMAGE) {
        style.backgroundImage = `url(${ bg_image })`;
    }

    const renderDesktop = () => (
        <a href={ data.link } className="desktop" rel="noopener noreferrer" target="_blank">
            <div className="inner-content">
                <div className="date">
                    { moment(data.date).format("MMMM D, YYYY") }
                    <h5 className="category">{ data._embedded['wp:term'][0][0].name }</h5>
                </div>

                { post_style === PostThumbnailStyle.IMAGE &&
                    <div className="content-wrapper">
                        <h3>{ data.title.rendered }</h3>
                        <h4>{ data._embedded.author.name }</h4>
                        <div className="read-more">Read more &rarr;</div>
                    </div>
                }

                { post_style === PostThumbnailStyle.COLOR &&
                    <div className="content-wrapper">
                        <h3>{ data.title.rendered }</h3>
                        <h4>{ data._embedded.author.name }</h4>
                        <p>{ data.excerpt.rendered.replace(/<.*?>/g, '') }</p>
                        <div className="read-more">Read more &rarr;</div>
                    </div>
                }

                { post_style === PostThumbnailStyle.QUOTE &&
                    <div className="content-wrapper">
                        <h5 className="quote">“</h5>
                        <h3>{ quote }</h3>
                        <h4>{ data._embedded.author.name }</h4>
                        <div className="read-more">Read more &rarr;</div>
                    </div>
                }
            </div>
        </a>
    );

    const renderMobile = () => (
        <div className="mobile">
            <div className="inner-content">
                <div className="date">{ moment(data.date).format("MMMM D, YYYY") }</div>
                { post_style === PostThumbnailStyle.IMAGE &&
                    <div className="content-wrapper">
                        <h3>{ data.title.rendered }</h3>
                        <h4>{ data._embedded.author.name }</h4>
                        <a href={ data.link } rel="noopener noreferrer" target="_blank">
                            <div className="NEButton">Read more &rarr;</div>
                        </a>
                    </div>
                }

                { post_style === PostThumbnailStyle.COLOR &&
                    <div className="content-wrapper">
                        <h3>{ data.title.rendered }</h3>
                        <h4>{ data._embedded.author.name }</h4>
                        <p>{ data.excerpt.rendered.replace(/<.*?>/g, '') }</p>
                        <a href={ data.link } rel="noopener noreferrer" target="_blank">
                            <div className="NEButton">Read more &rarr;</div>
                        </a>
                    </div>
                }

                { post_style === PostThumbnailStyle.QUOTE &&
                    <div className="content-wrapper">
                        <h5 className="quote">“</h5>
                        <h3>{ quote }</h3>
                        <h4>{ data._embedded.author.name }</h4>
                        <a href={ data.link } rel="noopener noreferrer" target="_blank">
                            <div className="NEButton">Read more &rarr;</div>
                        </a>
                    </div>
                }
            </div>
        </div>
    );

    return (
        <div className={ classes } style={ style } data-style={ post_style }>
            { renderDesktop() }
            { renderMobile() }
        </div>
    );
};

PostThumbnail.propTypes = {
    data: PropTypes.object.isRequired,
};

export default PostThumbnail;
