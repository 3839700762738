// Modules.
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components.
import DangerousCopy from '../DangerousCopy';
import ShowMoreToggle from '../ShowMoreToggle';

// Styles.
import './ShowMoreList.scss';

const ShowMoreList = ({ data }) => {

    const [ hidden, setHidden ] = useState(true);

    return (
        <div className="ShowMoreList">
            <div className="wrapper">
                <div className="content">
                    { !hidden &&
                        <div className="blocks">
                            { data.show_more_list_copies.map((block, key) => (
                                <div className="block-item" key={ key }>
                                    <h5>
                                        <span>{ key + 1 }</span>
                                        { block.header }
                                    </h5>
                                    <DangerousCopy copy={ block.copy } />
                                </div>
                            )) }
                        </div>
                    }
                </div>

                <ShowMoreToggle onClick={ () => setHidden(!hidden) } showing={ !hidden } />
            </div>
        </div>
    );

};

ShowMoreList.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ShowMoreList;
