// Modules.
import axios from 'axios';

// Config.
const host = process.env.REACT_APP_API_URL;
const base = process.env.REACT_APP_API_PATH;

async function sendContactEmail(formData) {
    return axios.post(`${ host }${ base }contact/send`, formData);
}

export default sendContactEmail;
