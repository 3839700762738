// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './DangerousCopy.scss';

const DangerousCopy = ({ copy }) => (
    <div className="DangerousCopy" dangerouslySetInnerHTML={{ __html: copy }} />
);

DangerousCopy.propTypes = {
    copy: PropTypes.string.isRequired,
};

export default DangerousCopy;
