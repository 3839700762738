// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './ModalCloseIcon.scss';

const ModalCloseIcon = ({ onClose }) => (
    <div className="ModalCloseIcon" onClick={ onClose }>
        <svg width='19' height='19' viewBox='0 0 19 19' xmlns='http://www.w3.org/2000/svg'>
            <g id='Marketing-Site-(Desktop)' fill='none' fillRule='evenodd'>
                <g id='Find-A-Doctor-Modal-(Positive)' transform='translate(-542 -49)'
                stroke='#F9F4E7' strokeWidth='1.5'>
                    <g id='Exit' transform='translate(543 50)'>
                        <path d='M0,0 L17,17' id='Stroke-1' />
                        <path d='M0,17 L17,0' id='Stroke-3' />
                    </g>
                </g>
            </g>
        </svg>
    </div>
);

ModalCloseIcon.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ModalCloseIcon;
