// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import ModalCloseIcon from '../ModalCloseIcon';

// Styles.
import './IFrameModal.scss';

const IFrameModal = ({ onClose, open, url }) => {

    const classes = classnames({
        'IFrameModal': true,
        'open': open,
    });

    return (
        <div className={ classes }>
            <div className="content">
                <ModalCloseIcon onClose={ onClose } />
                <iframe title={ url } src={ url } />
            </div>
        </div>
    );

};

IFrameModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    url: PropTypes.string,
};

IFrameModal.defaultProps ={
    open: false,
    url: null,
};

export default IFrameModal;


