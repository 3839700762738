import { combineReducers } from 'redux';

import global from './global';
import loading from './loading';
import menu from './menu';
import modal from './modal';
import search from './search';

export default combineReducers({
    global,
    loading,
    menu,
    modal,
    search,
});
