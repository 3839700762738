// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import DangerousCopy from '../DangerousCopy/DangerousCopy';

// Styles.
import './WriteItOut.scss';

const WriteItOut = ({ data }) => (
    <div className={ classnames({
        'WriteItOut': true,
        'no-header': !data.header,
        'no-subheader': !data.subheader,
        'no-copy': !data.copy,
    }) }>
        <div className="wrapper">
            <h2>{ data.header }</h2>
            <h6>{ data.subheader }</h6>
            { data.copy && <DangerousCopy copy={ data.copy } /> }
        </div>
    </div>
);

WriteItOut.propTypes = {
    data: PropTypes.object.isRequired,
};

export default WriteItOut;
