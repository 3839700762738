// Modules.
import Url from 'url-parse';

// Constants.
import { WP_QUERY_IDS } from './constants';

/**
 * Builds a Menu Structure for the React app using the Menu data from Wordpress.
 *
 */
export const constructMenuStructure = (menuData) => {
    if (!menuData) return [];

    const sortByMenuOrder = (a, b) => (a.menu_order - b.menu_order);

    const items = {};
    for (let menuItemData of menuData) {

        const item = { ...menuItemData };
        item.__children = [];
        item.__pathname = item.object === WP_QUERY_IDS.CUSTOM
            ? menuItemData.url
            : convertToRelativeURL(menuItemData.url);

        if (item.__pathname === '/') continue;
        if (item.object === WP_QUERY_IDS.FIND_A_DOCTOR) {
            item.__pathname = null;
        }

        if (item.menu_item_parent === '0') { // Primary Menu Item
            items[item.ID] = item;
        } else { // Secondary Menu Item
            item.__parent = items[item.menu_item_parent];
            items[item.menu_item_parent].__children.push(item);
        }
    }

    const ordered = Object.values(items);
    for (let orderedItem of ordered) {
        orderedItem.__children.sort(sortByMenuOrder);
    }

    return ordered.sort(sortByMenuOrder);
};


/**
 * Retrieves the pathname of an absolute URL
 */
export const convertToRelativeURL = (abs) => {
    const re = new RegExp(process.env.REACT_APP_API_FOLDER);
    const modded = abs.replace(re, '');
    return new Url(modded).pathname;
};
