// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './HighlightCopyBlock.scss';

const HighlightCopyBlock = ({ copy, highlight }) => {

    const formatted = highlight
        ? copy.replace(highlight, `<span class="highlight">${ highlight }</span>`)
        : copy;

    return (
        <div className="HighlightCopyBlock" dangerouslySetInnerHTML={ { __html: formatted }} />
    );

};

HighlightCopyBlock.propTypes = {
    copy: PropTypes.string.isRequired,
    highlight: PropTypes.string,
};

HighlightCopyBlock.defaultProps = {
    highlight: null,
};

export default HighlightCopyBlock;
