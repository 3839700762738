// Modules.
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import FadForm from '../../../FadForm';
import ModalCloseIcon from '../ModalCloseIcon';
import ModalCloseTrigger from '../ModalCloseTrigger';

// Context.
import { StageContext } from '../../../../context';

// Utils.
import { TABLET_PORTRAIT } from '../../../../utils/constants';

// Styles.
import './FadModal.scss';

const FadModal = ({ onClose, open }) => {

    const context = useContext(StageContext);
    const classes = classnames({
        'FadModal': true,
        'open': open,
    });

    return (
        <div className={ classes }>
            <div className="content">
                <ModalCloseIcon onClose={ onClose } />
                <h3>Find a Doctor</h3>
                <FadForm theme="cream" buttonTheme={ context.width >= TABLET_PORTRAIT } />
                <ModalCloseTrigger onClose={ onClose } />
            </div>
        </div>
    );

};

FadModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
};

FadModal.defaultProps ={
    open: false,
};

export default FadModal;
