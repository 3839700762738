// Modules.
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import YouTube from 'react-youtube';

// Components.
import ModalCloseIcon from '../ModalCloseIcon';

// Utils.
import { StageContext } from '../../../../context';

// Styles.
import './VideoModal.scss';

const VideoModal = ({ data, onClose, open }) => {

    const context = useContext(StageContext);
    const [ videoRef, setVideoRef ] = useState(null);
    const classes = classnames({
        'VideoModal': true,
        'open': open,
        'calc-by-height': (context.width / context.height) >= (16 / 9),
    });

    useEffect(() => {
        if (!open && videoRef) {
            videoRef.stopVideo();
        }
    }, [ open ]);

    if (open && !(data && data.id)) {
        console.error("[VideoModal] Missing Video ID");
        onClose();
        return null;
    }

    return (
        <div className={ classes }>
            <div className="content">

                <ModalCloseIcon onClose={ onClose } />
                <div className="video-wrapper">
                    <YouTube videoId={ open ? data.id : null } onReady={ (e) => { setVideoRef(e.target); }} />
                </div>

            </div>
        </div>
    );

};

VideoModal.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
};

VideoModal.defaultProps = {
    data: null,
    open: false,
};

export default VideoModal;
