// Modules.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import FadDropdown from '../FadDropdown';
import MenuLink from '../../../MenuLink';
import LinkDropdown from '../LinkDropdown';

// Constants.
import { WP_QUERY_IDS } from '../../../../utils/constants';

// Styles.
import './HeaderMenuItem.scss';

const HeaderMenuItem = ({ collapsed, current, item }) => {

    const [ hovered, setHovered ] = useState(false);

    const classes = classnames({
        'HeaderMenuItem': true,
        'collapsed': collapsed,
        'hovered': hovered,
        'active': current && current.ID === item.ID,
    });

    const renderTitle = () => {
        const inner = (
            <h5
                onMouseEnter={ () => { setHovered(true) } }
                onMouseLeave={ () => { setHovered(false) } }
            >
                {/* Double-titles for hover-state */}
                <span>{ item.title }</span>
                <span>{ item.title }</span>
            </h5>
        );

        if (!item.__pathname) return inner;

        return (
            <MenuLink data={ item }>{ inner }</MenuLink>
        );
    };

    return (
        <div className={ classes }>
            { renderTitle() }

            { item.object === WP_QUERY_IDS.FIND_A_DOCTOR &&
                <FadDropdown
                    item={ item }
                    updateHoverState={ setHovered }
                    hovered={ hovered }
                />
            }

            { item.__children.length > 0 &&
                <LinkDropdown
                    item={ item }
                    updateHoverState={ setHovered }
                    hovered={ hovered }
                />
            }
        </div>
    );

};

HeaderMenuItem.propTypes = {
    item: PropTypes.object.isRequired,
    collapsed: PropTypes.bool,
    current: PropTypes.object,
};

HeaderMenuItem.defaultProps = {
    collapsed: false,
};

export default HeaderMenuItem;
