// Modules.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import ArrowIcon from '../../../ArrowIcon';

// Constants.
import { TABLET_PORTRAIT } from '../../../../utils/constants';

// Styles.
import './LoginMobileMenuItem.scss';

const LoginMobileMenuItem = ({ gvars }) => {

    const [expanded, setExpanded] = useState(false);
    const classes = classnames({
        'LoginMobileMenuItem': true,
        expanded,
    });

    return (
        <div className={ classes } style={ { maxWidth: `${ TABLET_PORTRAIT }px` }}>
            <h5 onClick={ () => setExpanded(!expanded) }>
                <span>{ gvars.login_menu_text }</span>
                <ArrowIcon theme="gold" down={ !expanded } />
            </h5>
            <ul className="dropdown">
                {
                    gvars.login_links && gvars.login_links.map((child, key) => (
                        <a key={ key } target={ child.link.target } href={ child.link.url }>
                            <li>
                                <span>{ child.link.title }</span>
                                <ArrowIcon theme="cream" right />
                            </li>
                        </a>
                    ))
                }
            </ul>
        </div>
    );

};

LoginMobileMenuItem.propTypes = {
    gvars: PropTypes.object,
};

LoginMobileMenuItem.defaultProps = {
    gvars: {},
};

export default LoginMobileMenuItem;
