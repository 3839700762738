// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles.
import './Button.scss';

const Button = ({ children, theme, hollow }) => {

    const classes = classnames({
        'Button': true,
        [theme]: true,
        hollow,
    });

    return (
        <button className={ classes }>
            <span>{ children }</span>
        </button>
    );

};

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
    ]).isRequired,
    theme: PropTypes.oneOf(['gold', 'cream', 'red']),
    hollow: PropTypes.bool,
};

Button.defaultProps = {
    theme: 'gold',
    hollow: true,
};

export default Button;
