// Modules.
import { connect } from 'react-redux';

// Components.
import SearchResults from './SearchResults';

// Store.
import {
    emptySearchResults,
    storeSearchResults,
    storeSearchValue,
} from '../../redux/actions/app';

const mapStateToProps = state => ({
    results: state.search.results,
    value: state.search.value,
});

const mapDispatchToProps = {
    emptySearchResults,
    storeSearchResults,
    storeSearchValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
