// Modules.
import axios from 'axios';

const base = process.env.REACT_APP_API_PATH;

export const getBlogHome = async (host) => {
    return axios.get(`${ host }${ base }pages&slug=home&_embed`)
        .then(res => { return res.data[0]; })
        .catch(err => {
            console.error("[Error] Unable to fetch Blog Home data.");
            return null;
        });
};

export const getLatestBlogPosts = async (host, number = 3) => {
    return axios.get(`${ host }${ base }posts&per_page=${ number }&_embed&orderby=date&order=desc`)
        .then(res => { return res.data; })
        .catch(err => {
            console.error("[Error] Unable to fetch Blog Posts data.");
            return [];
        });
};
