// Modules.
import { connect } from 'react-redux';

// Components.
import FadForm from './FadForm';

// Store.
const mapStateToProps = state => ({
    fad: state.menu.fad,
});

export default connect(mapStateToProps)(FadForm);
