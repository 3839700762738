// Modules.
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components.
import SearchIcon from '../SearchIcon';
import SearchResultsPreview from './elements/SearchResultsPreview';

// API.
import { searchAll } from '../../api/search';

// Styles.
import './SearchResults.scss';

const SearchResults = ({
    emptySearchResults,
    results,
    storeSearchResults,
    storeSearchValue,
    value ,
}) => {

    const [ searching, setSearching ] = useState(false);

    const handleValueChange = event => {
        storeSearchValue(event.target.value);
    };

    const handleSearch = async event => {
        event.preventDefault();

        setSearching(true);
        const results = await searchAll({ search: value });
        storeSearchResults(results);
        setSearching(false);
    };

    useEffect(() => {
        return () => {
            storeSearchValue('');
            emptySearchResults();
        };
    }, []);

    return (
        <div className="SearchResults">
            <div className="wrapper">
                <h5>Search Results</h5>

                <form onSubmit={ handleSearch }>
                    <div className="search-input">
                        <SearchIcon />
                        <input
                            type="text"
                            value={ value }
                            placeholder="Begin Searching..."
                            onChange={ handleValueChange }
                        />
                        <button className="submitter">→</button>
                    </div>
                </form>

                { results &&
                    <div className="search-results-count">
                        We found { results.length } results for your search
                    </div>
                }

                <div className="search-results">
                    { searching && <div className="h8">Searching...</div> }
                    { !searching && results &&
                        <SearchResultsPreview results={ results } searchTerm={ value } />
                    }
                </div>
            </div>
        </div>
    );
};

SearchResults.propTypes = {
    emptySearchResults: PropTypes.func.isRequired,
    results: PropTypes.array,
    storeSearchResults: PropTypes.func.isRequired,
    storeSearchValue: PropTypes.func.isRequired,
    value: PropTypes.string,
};

SearchResults.defaultProps = {
    results: null,
    value: '',
};

export default SearchResults;
