// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './Loader.scss';

const Loader = ({ active, white }) => (
    <section className={ `Loader${ active ? ' active' : '' }` }>
        <div className={ `spinner${ white ? ' white' : '' }` }>
            <div className="circle one"></div>
            <div className="circle two"></div>
            <div className="circle three"></div>
        </div>
    </section>
);

Loader.propTypes = {
    active: PropTypes.bool,
    white: PropTypes.bool,
};

Loader.defaultProps = {
    active: false,
    white: false,
};

export default Loader;
