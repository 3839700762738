// Modules.
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Utils.
import { MODAL_TYPES, WP_QUERY_IDS } from '../../utils/constants';
import { convertToRelativeURL } from '../../utils/menu';

const MenuLink = ({ anchors, children, className, data, openModal }) => {    

    const classes = classnames({
        [className]: true,
        'MenuLink': true,
        'anchor': data.object === WP_QUERY_IDS.ANCHOR_LINK,
        'secondary': data.classes[0] == 'secondary'
    });

    switch (data.object) {
        case WP_QUERY_IDS.ANCHOR_LINK: {
            const anchor = anchors.find(a => a.id === parseInt(data.object_id));
            if (!anchor) return null;

            const pathname = convertToRelativeURL(anchor.acf.page);
            return (
                <Link
                    className={ classes }
                    to={ {
                        pathname: pathname.replace(/\/$/, ''),
                        hash: `#${ anchor.acf.anchor_id }`,
                    } }
                >
                    { children }
                </Link>
            );
        }

        case WP_QUERY_IDS.CUSTOM:
            return (
                <a
                    className={ classes }
                    href={ data.__pathname }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    { children }
                </a>
            );

        case WP_QUERY_IDS.FIND_A_DOCTOR:
            return (
                <span className={ classes } onClick={ () => { openModal(MODAL_TYPES.FAD_MODAL); } }>
                    { children }
                </span>
            );

        case WP_QUERY_IDS.PAGE:
            return (
                <Link
                    className={ classes }
                    to={ data.__pathname }
                >
                    { children }
                </Link>
            );

        default:
            return null;
    }

};

MenuLink.propTypes = {
    anchors: PropTypes.array.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
    visible: PropTypes.bool,
};

MenuLink.defaultProps = {
    className: '',
};

export default MenuLink;
