// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// import filesize from 'filesize';

// Components.
import HoverHeader from '../HoverHeader/';

// Styles.
import './DownloadablesCondensed.scss';

const DownloadablesCondensed = ({ data }) => {

    const classes = classnames({
        'DownloadablesCondensed': true,
        'shadowed': data.is_shadowed,
        'full-width': data.is_full_width,
        'remove-top-margin': data.remove_top_margin,
    });

    const getDownloadableLabel = (downloadables) => {
        const title = downloadables.file.title;
        // const size = filesize(downloadables.file.filesize);

        let type = '';
        switch (downloadables.file.mime_type) {
            case 'application/pdf':
                type = ' PDF';
                break;
            default:
                break;
        }

        return (
            <a href={ downloadables.file.url } target="_blank" rel="noopener noreferrer">
                <HoverHeader>
                    <div className="header-content">{ `${ title } (${ type })` }</div>
                </HoverHeader>
                { downloadables.file.mime_type === 'application/pdf' &&
                    <img src="/assets/images/icon_pdf.svg" alt="PDF Icon" />
                }
            </a>
        );
    };

    return (
        <div className={ classes }>
            <div className="wrapper">
                { data.header && <h5>{ data.header }</h5> }

                <div className="files">
                    { data.documents.length > 0 &&
                        <ul>
                            { data.documents.map((document, key) => (
                                document.file && <li key={ key }>{ getDownloadableLabel(document) }</li>
                            )) }
                        </ul>
                    }
                </div>
            </div>
        </div>
    );
};

DownloadablesCondensed.propTypes = {
    data: PropTypes.object.isRequired,
};

export default DownloadablesCondensed;
