// Modules.
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components.
import SearchMobileMenuItem from './SearchMobileMenuItem';

// Store.
import { storeSearchResults, storeSearchValue } from '../../../../redux/actions/app';

const mapStateToProps = state => ({
    gvars: state.global.gvars,
});

const mapDispatchToProps = {
    storeSearchResults,
    storeSearchValue,
};

// Exports.
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SearchMobileMenuItem)
);
