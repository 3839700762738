// Modules.
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import SearchIcon from '../../../SearchIcon';

// API.
import { searchMedia, searchPages } from '../../../../api/search';

// Context.
import { HeaderMobileContext } from '../../../../context';

// Utils.
import { TABLET_PORTRAIT } from '../../../../utils/constants';
import { convertToRelativeURL } from '../../../../utils/menu';

// Styles.
import './SearchMobileMenuItem.scss';

const SearchMobileMenuItem = ({ gvars, history, storeSearchResults, storeSearchValue }) => {

    const [ searching, setSearching ] = useState(false);
    const [ searchValue, setSearchValue ] = useState('');
    const context = useContext(HeaderMobileContext);

    const fetch = () => {
        return Promise.all([
            searchMedia({ search: searchValue, mime_type: 'application/pdf' }),
            searchPages({ search: searchValue }),
        ]).then(res => {
            const medias = res.shift();
            const pages = res.shift();

            return medias.concat(pages);
        });
    };

    const handleValueChange = event => {
        storeSearchValue(event.target.value);
        setSearchValue(event.target.value);
    };

    const handleSearch = async event => {
        event.preventDefault();

        setSearching(true);

        const results = await fetch();
        storeSearchResults(results);

        setSearching(false);
        history.push(convertToRelativeURL(gvars.search_results_page));
        context.setOpened(false);
    };

    const classes = classnames({
        'SearchMobileMenuItem': true,
        'disabled': searching,
    });

    return (
        <div className={ classes } style={ { maxWidth: `${ TABLET_PORTRAIT }px` }}>
            <form onSubmit={ handleSearch }>
                <div className="search-input">
                    <SearchIcon />
                    <input
                        type="text"
                        value={ searchValue }
                        placeholder="Begin Searching..."
                        onChange={ handleValueChange }
                    />
                    <button className="submitter">→</button>
                </div>
            </form>
        </div>
    );

};

SearchMobileMenuItem.propTypes = {
    gvars: PropTypes.object,
    history: PropTypes.object.isRequired,
    storeSearchResults: PropTypes.func.isRequired,
    storeSearchValue: PropTypes.func.isRequired,
};

SearchMobileMenuItem.defaultProps = {
    gvars: {},
};

export default SearchMobileMenuItem;
