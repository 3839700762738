// Modules.
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components.
import Button from '../Button';
import FeaturedArticle from './elements/FeaturedArticle';
import PostGrid from './elements/PostGrid';

// API.
import { getBlogHome, getLatestBlogPosts } from '../../api/blog';

// Styles.
import './BlogPreview.scss';

const BlogPreview = ({ gvars, updatePageLoadCounter }) => {

    if (!gvars.blog_link) return null;

    const [ home, setHome ] = useState(null);
    const [ posts, setPosts ] = useState([]);

    const fetch = () => {
        return Promise.all([
            getBlogHome(gvars.blog_link.url),
            getLatestBlogPosts(gvars.blog_link.url),
        ]);
    };

    const load = async () => {
        updatePageLoadCounter(1);
        const dataset = await fetch();

        setHome(dataset.shift());
        setPosts([ ...dataset.shift() ]);

        updatePageLoadCounter(-1);
    };

    useEffect(() => {
        load();
    }, []);

    if (!home) return null;

    // Set the Link to the Featured Article from the Slug
    home.acf.featured_article.link = `${ gvars.blog_link.url }/${ home.acf.featured_article.post_name }`;

    return (
        <div className="BlogPreview">
            <div className="wrapper">
                <img className="title-treatment desktop" src={ home.acf.title_treatment } alt="Notch 8: An Uphealth Chronicle" />
                <div className="mobile-title-treatment-wrapper">
                    <img className="title-treatment mobile" src={ home.acf.title_treatment_mobile } alt="Notch 8: An Uphealth Chronicle" />
                </div>

                <FeaturedArticle data={ { ...home.acf.featured_article, _embedded: home._embedded } } />
                <PostGrid posts={ posts } />

                <div className="read-all-content">
                    <h3>Read all of our articles on NotchEight.</h3>
                    <a href={ gvars.blog_link.url } rel="noopener noreferrer" target="_blank">
                        <Button theme="red" hollow={ false }>Visit Notch Eight</Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

BlogPreview.propTypes = {
    gvars: PropTypes.object,
    updatePageLoadCounter: PropTypes.func.isRequired,
};

BlogPreview.defaultProps = {
    gvars: {},
};

export default BlogPreview;
