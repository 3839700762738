// Modules.
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Constants.
import { TABLET_PORTRAIT } from '../../utils/constants';

// Context.
import { StageContext } from '../../context';

// Styles.
import './ImageBlock.scss';

const ImageBlock = ({ data }) => {

    const context = useContext(StageContext);
    if (context.width < TABLET_PORTRAIT) return null;

    const classes = classnames({
        'ImageBlock': true,
        'has-margins': data.has_margins,
    });

    return (
        <div className={ classes }>
            <img src={ data.image.url } alt={ data.image.title } />
        </div>
    );

};

ImageBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ImageBlock;
