// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import ACFLink from '../ACFLink';
import Button from '../Button';
import DangerousCopy from '../DangerousCopy';

// Constants.
import { ACF_LINK_TYPES } from '../../utils/constants';

// Styles.
import './FloatingSection.scss';

const FloatingSection = ({ data }) => {

    const classes = classnames({
        'FloatingSection': true,
        'shadowed': data.is_shadowed,
        'full-width': data.is_full_width,
    });

    return (
        <div className={ classes }>
            <div className="wrapper">
                <h3>{ data.header }</h3>
                <DangerousCopy copy={ data.copy } />
                { data.link_type !== ACF_LINK_TYPES.NONE &&
                    <ACFLink data={ data }>
                        <Button theme="gold" hollow={ true }>{ data.button_label }</Button>
                    </ACFLink>
                }
            </div>
        </div>
    );
};

FloatingSection.propTypes = {
    data: PropTypes.object.isRequired,
};

export default FloatingSection;
