// Modules.
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components.
import ArrowIcon from '../../../ArrowIcon';
import HighlightCopyBlock from '../../../HighlightCopyBlock';

// Utils.
import { WP_QUERY_IDS } from '../../../../utils/constants';
import { convertToRelativeURL } from '../../../../utils/menu';

// Styles.
import './SearchResultsPreview.scss';

const SearchResultsPreview = ({ results, searchTerm }) => {

    const getHeaderBlock = (result, key) => (
        <h5 dangerouslySetInnerHTML={
            { __html: `${ key + 1 }. ${ result.title.rendered }` }
        } />
    );

    const getCopyBlock = result => {
        let raw;
        switch (result.type) {
            case WP_QUERY_IDS.ATTACHMENT:
                raw = result.caption.rendered;
                break;

            case WP_QUERY_IDS.PAGE:
                raw = result.excerpt.rendered;
                break;

            default:
                break;
        }

        if (!raw) return;
        const cleaned = raw.replace(/<.*?>/g, '');
        return <HighlightCopyBlock copy={ cleaned } highlight={ searchTerm } />;
    };

    const renderPreviewBlock = (result, key) => {

        switch (result.type) {

            case WP_QUERY_IDS.ATTACHMENT:
                return (
                    <a href={ result.source_url } target="_blank" rel="noopener noreferrer" key={ key }>
                        <div className="result">
                            { getHeaderBlock(result, key) }
                            { getCopyBlock(result) }

                            <div className="learn-more">
                                <span>Learn more</span> <ArrowIcon theme="gold" right={ true } />
                            </div>
                        </div>
                    </a>
                );

            case WP_QUERY_IDS.PAGE:
                return (
                    <Link to={ convertToRelativeURL(result.link) } key={ key }>
                        <div className="result">
                            { getHeaderBlock(result, key) }
                            { getCopyBlock(result) }

                            <div className="learn-more">
                                <span>Learn more</span> <ArrowIcon theme="gold" right={ true } />
                            </div>
                        </div>
                    </Link>
                );

            default:
                return null;

        }

    };

    return (
        <div className="SearchResultsPreview">
            { results.map(renderPreviewBlock) }
        </div>
    );

};

SearchResultsPreview.propTypes = {
    results: PropTypes.array,
    searchTerm: PropTypes.string.isRequired,
};

SearchResultsPreview.defaultProps = {
    result: [],
};

export default SearchResultsPreview;
