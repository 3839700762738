// Modules.
import axios from 'axios';

// Constants.
import { WP_QUERY_IDS } from '../utils/constants';

// Config.
const host = process.env.REACT_APP_API_URL;
const base = process.env.REACT_APP_API_PATH;

async function getNewsData(count = 100, page = 1) {
    return axios.get(`${ host }${ base }${ WP_QUERY_IDS.NEWS_POSTS }&page=${ page }&per_page=${ count }&orderby=date&order=desc`).then(res => {
        return [
            res.data,
            parseInt(res.headers['x-wp-totalpages']),
            parseInt(res.headers['x-wp-total']),
        ];
    });
}

async function getNewsDataById(id) {
    return axios.get(`${ host }${ base }${ WP_QUERY_IDS.NEWS_POSTS }/${id}`).then(res => {
        return res.data;
    });
}

async function shareArticle(email, title, url) {
    return axios.post(
        `${ host }${ base }share/article`,
        { email, title, url }
    );
}

export { getNewsData, getNewsDataById, shareArticle };
export default getNewsData;
