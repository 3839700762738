// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import ACFLink from '../ACFLink';
import Button from '../Button';

// Context.
import { StageContext } from '../../context';

// Utils.
import { ACF_LINK_TYPES, TABLET_PORTRAIT } from '../../utils/constants';

// Styles.
import './HeroImage.scss';

const HeroImage = ({ data }) => (
    <StageContext.Consumer>
        {({ width }) => (
            <div className="HeroImage" style={{ backgroundImage: `url(${ (width < TABLET_PORTRAIT && data.mobile_image.url) ? data.mobile_image.url : data.image.url })` }}>
                <div className="wrapper">
                    <h2>{ data.header }</h2>
                    <h6>{ data.copy }</h6>
                    { data.link_type !== ACF_LINK_TYPES.NONE &&
                        <ACFLink data={ data }>
                            <Button theme="cream" hollow={ false }>{ data.button_label }</Button>
                        </ACFLink>
                    }
                </div>
            </div>
        )}
    </StageContext.Consumer>
);

HeroImage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default HeroImage;
