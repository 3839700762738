// Modules.
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import HeaderLogoBar from './elements/HeaderLogoBar';
import HeaderMenu from './elements/HeaderMenu';
import HeaderSubmenu from './elements/HeaderSubmenu';
import HeaderTagOverlay from './elements/HeaderTagOverlay';

// Utils.
import { constructMenuStructure } from '../../utils/menu';

// Styles.
import './Header.scss';

const Header = ({ gvars, location, primary }) => {

    if (!primary) return null;

    const menu = constructMenuStructure(primary.items);

    const current = menu.find(menuItem => {
        const pathname = location.hash ? `${ location.pathname}/` : location.pathname;
        return menuItem.__pathname === pathname;
    });

    const hasSubmenu = current && current.__children && current.__children.length > 0;

    const classes = classnames({
        'Header': true,
        'collapsed': hasSubmenu,
    });

    return (
        <div className={ classes }>
            { gvars.header_tag &&
                <Link to="/">
                    <HeaderTagOverlay>{ gvars.header_tag }</HeaderTagOverlay>
                </Link>
            }
            <HeaderLogoBar collapsed={ hasSubmenu } />
            <HeaderMenu data={ menu } current={ current } collapsed={ hasSubmenu } />
            { hasSubmenu && <HeaderSubmenu data={ current.__children } /> }
        </div>
    );

};

Header.propTypes = {
    gvars: PropTypes.object,
    primary: PropTypes.object,
};

Header.defaultProps = {
    gvars: {},
    primary: null,
};

export default Header;
