// Modules.
import { connect } from 'react-redux';

// Components.
import ModalContainer from './ModalContainer';
import { closeModal } from '../../redux/actions/app';

// Store.
const mapStateToProps = state => ({
    data: state.modal.data,
    modalType: state.modal.modalType,
});

const mapDispatchToProps = {
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
