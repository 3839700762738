// Modules.
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import FadModal from './elements/FadModal';
import IFrameModal from './elements/IFrameModal';
import VideoModal from './elements/VideoModal';

// Utils.
import { MODAL_TYPES } from '../../utils/constants';
import { hideOverflow } from '../../utils/layout';

// Styles.
import './ModalContainer.scss';

const ModalContainer = ({ closeModal, data, modalType }) => {

    useEffect(() => {
        if (modalType) {
            hideOverflow(true);
        }
    }, [ modalType ]);

    const onClose = () => {
        hideOverflow(false);
        closeModal();
    };

    const classes = classnames({
        'ModalContainer': true,
        'open': modalType,
    });

    const usePrimary = modalType === MODAL_TYPES.VIDEO_MODAL;
    const useSide = modalType === MODAL_TYPES.FAD_MODAL
        || modalType === MODAL_TYPES.JOTFORM_MODAL;

    return (
        <div className={ classes }>

            <div className={ classnames({ 'primary-modal-container': true, 'disabled': !usePrimary }) }>
                <div className="off-modal" onClick={ onClose } />
                <VideoModal
                    onClose={ onClose }
                    open={ modalType === MODAL_TYPES.VIDEO_MODAL }
                    data={ data }
                />
            </div>

            <div className={ classnames({ 'side-modal-container': true, 'disabled': !useSide }) }>
                <div className="off-modal" onClick={ onClose } />
                <FadModal
                    onClose={ onClose }
                    open={ modalType === MODAL_TYPES.FAD_MODAL }
                />
                <IFrameModal
                    onClose={ onClose }
                    open={ modalType === MODAL_TYPES.JOTFORM_MODAL }
                    url={ data ? data.url : null }
                />
            </div>

        </div>
    );

};

ModalContainer.propTypes = {
    closeModal: PropTypes.func.isRequired,
    data: PropTypes.object,
    modalType: PropTypes.string,
};

ModalContainer.defaultProps = {
    data: null,
    modalType: null,
};

export default ModalContainer;
