export const WP_QUERY_IDS = {
    ANCHOR_LINK: 'anchorlinks',
    ATTACHMENT: 'attachment',
    CUSTOM: 'custom',
    FILE: 'file',
    FIND_A_DOCTOR: 'findadoctor',
    NEWS_POSTS: 'newsposts',
    PAGE: 'page',
    SOCIAL_MEDIAS: 'socialmedias',
};

export const ACF_LAYOUT_IDS = {
    ADDITIONAL_RESOURCES_SECTION: 'additional_resources_section',
    ALIGNED_COPY_LIST: 'aligned_copy_list',
    BLOG_PREVIEW: 'blog_preview',
    CONTACT_FORM: 'contact_form',
    HERO_CAROUSEL: 'hero_carousel',
    HERO_COPY: 'hero_copy',
    HERO_IMAGE: 'hero_image',
    DOWNLOADABLES: 'downloadables',
    DOWNLOADABLES_CONDENSED: 'downloadables_condensed',
    FLOATING_SECTION: 'floating_section',
    IMAGE_BLOCK: 'image_block',
    LIST_IT_OUT: 'list_it_out',
    MATRIX_GALLERY: 'matrix_gallery',
    NEWS_SECTION: 'news_section',
    QUICK_INFO: 'quick_info_section',
    SEARCH_RESULTS: 'search_results',
    SHOW_LINKS_SECTION: 'show_links_section',
    SHOW_MORE_LIST: 'show_more_list',
    SHOW_MORE_SECTION: 'show_more_section',
    SIDEKICK_IMAGE: 'sidekick_image',
    TIMELINE: 'timeline',
    VILLAIN_IMAGE: 'villain_image',
    WRITE_IT_OUT: 'write_it_out_section',
};

export const ACF_LINK_TYPES = {
    NONE: 'none',
    INTERNAL: 'internal',
    EXTERNAL: 'external',
    FAD_MODAL: 'fad',
    JOTFORM_MODAL: 'jotform',
    VIDEO_MODAL: 'video',
};

export const MODAL_TYPES = {
    FAD_MODAL: 'FAD_MODAL',
    JOTFORM_MODAL: 'JOTFORM_MODAL',
    VIDEO_MODAL: 'VIDEO_MODAL',
};

export const PHONE = 480;
export const TABLET_PORTRAIT = 768;
export const TABLET_LANDSCAPE = 1024;
export const DESKTOP_LARGE = 1920;

export const MENU_BREAK = 1099;
