import {
    EMPTY_SEARCH_RESULTS,
    STORE_SEARCH_RESULTS,
    STORE_SEARCH_VALUE,
} from '../actions/types';

const initialState = {
    results: null,
    value: '',
};

export default function appReducer(state = initialState, action) {

    switch (action.type) {

        case EMPTY_SEARCH_RESULTS: {
            return {
                ...state,
                results: null,
            };
        }

        case STORE_SEARCH_RESULTS: {
            const results = action.payload.data;
            results.sort((a, b) => b.id - a.id);

            return {
                ...state,
                results,
            };
        }

        case STORE_SEARCH_VALUE:
            return {
                ...state,
                value: action.payload.data,
            }

        default:
            return state;

    }

};
