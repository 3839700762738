// Modules.
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components.
import SearchDropdown from './SearchDropdown';

// Store.
import { storeSearchResults, storeSearchValue } from '../../redux/actions/app';

const mapStateToProps = state => ({
    gvars: state.global.gvars,
    results: state.search.results,
});

const mapDispatchToProps = {
    storeSearchResults,
    storeSearchValue,
};

// Exports.
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SearchDropdown)
);
