import React, { useState } from 'react';

// Components.
import DangerousCopy from '../DangerousCopy';
import ShowMoreToggle from '../ShowMoreToggle';
import ListItOutItem from '../ListItOutItem';

// Styles.
import './ListItOut.scss';

const ListItOut = ({ data }) => {

    const [ expanded, setExpanded ] = useState(false);

    const count = data.collapsible
        ? Math.min(data.list_item.length, parseInt(data.collapsed_item_count))
        : data.list_item.length;

    const baseItems = data.list_item.slice(0, count);
    const expandItems = count < data.list_item.length
        ? data.list_item.slice(count, data.list_item.length)
        : [];

    return (
        <div className="ListItOut">
            <div className="wrapper">
                { data.header && <h5>{ data.header }</h5> }

                <div className="list-item-container">
                    { baseItems.map((listItem, key) => (
                        <div className="list-item" key={ key }>
                            {listItem.collapse_list_item && listItem.collapse_list_item === true ? 
                                <ListItOutItem listItem={listItem} />
                                : 
                                <>
                                    <h6>{ listItem.list_item_header }</h6>
                                    <DangerousCopy copy={ listItem.list_item_copy } />
                                </>
                            }
                        </div>
                    )) }

                    { expanded && expandItems.map((listItem, key) => (
                        <div className="list-item" key={ key }>
                            {listItem.collapse_list_item && listItem.collapse_list_item === true ? 
                                <ListItOutItem listItem={listItem} />
                                : 
                                <>
                                    <h6>{ listItem.list_item_header }</h6>
                                    <DangerousCopy copy={ listItem.list_item_copy } />
                                </>
                            }
                        </div>
                    )) }
                </div>

                { data.collapsible && data.list_item.length > parseInt(data.collapsed_item_count) &&
                    <ShowMoreToggle
                        onClick={ () => { setExpanded(!expanded) }}
                        showing={ expanded }
                        theme="blue"
                    />
                }
            </div>
        </div>
    );

};

export default ListItOut;
