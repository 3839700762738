// Modules.
import { connect } from 'react-redux';

// Components.
import FeedbackIcon from './FeedbackIcon';

// Store.
const mapStateToProps = state => ({
    gvars: state.global.gvars,
});

export default connect(mapStateToProps)(FeedbackIcon);