// Modules.
import { connect } from 'react-redux';

// Components.
import LoginMobileMenuItem from './LoginMobileMenuItem';

const mapStateToProps = state => ({
    gvars: state.global.gvars,
});

export default connect(mapStateToProps)(LoginMobileMenuItem);
