// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './HeaderMobileTagBar.scss';

const HeaderMobileTagBar = ({ children }) => (
    <div className='HeaderMobileTagBar'>
        { children }
    </div>
);

HeaderMobileTagBar.propTypes = {
    children: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
};

export default HeaderMobileTagBar;
