// Modules.
import { connect } from 'react-redux';

// Components.
import Footer from './Footer';

// Store.
const mapStateToProps = state => ({
    gvars: state.global.gvars,
    footer: state.menu.footer,
    legal: state.menu.legal,
    socials: state.menu.socials,
});

export default connect(mapStateToProps)(Footer);
