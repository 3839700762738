import { CLOSE_MODAL, OPEN_MODAL } from '../actions/types';

const initialState = {
    data: null,
    modalType: null,
};

export default function appReducer(state = initialState, action) {

    switch (action.type) {

        case CLOSE_MODAL:
            return {
                ...state,
                data: null,
                modalType: null,
            };

        case OPEN_MODAL:
            return {
                ...state,
                data: action.payload.data,
                modalType: action.payload.modalType,
            };

        default:
            return state;

    }

};
