// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import CarouselArrowIcon from '../../../CarouselArrowIcon';

// Styles.
import './TimelineControls.scss';

const TimelineControls = ({ count, current, onClick, onNext, onPrev }) => (

    <div className="TimelineControls">
        <div className="prev" onClick={ () => onPrev() }>
            <CarouselArrowIcon />
        </div>

        <div className="markers">
            { [ ...Array(count) ].map((value, key) => (
                <div
                    key={ key }
                    className={ classnames({
                        'marker': true,
                        'active': current === key,
                    }) }
                    onClick={ () => onClick(key) }
                />
            )) }
        </div>

        <div className="next" onClick={ () => onNext() }>
            <CarouselArrowIcon theme="gold" strokeWidth={ 3 } />
        </div>
    </div>

);

TimelineControls.propTypes = {
    count: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
};

export default TimelineControls;
