// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import DangerousCopy from '../DangerousCopy';

// Styles.
import './HeroCopy.scss';

const HeroCopy = ({ data }) => {
    const classes = classnames({
        'HeroCopy': true,
        'shadowed': data.is_shadowed,
    });

    return (
        <div className={ classes }>
            <div className="wrapper">
                <h2>{ data.header }</h2>
                { data.subheader && <h6>{ data.subheader }</h6> }
                <DangerousCopy copy={ data.copy } />
            </div>
        </div>
    );
};

HeroCopy.propTypes = {
    data: PropTypes.object.isRequired,
};

export default HeroCopy;
