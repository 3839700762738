// Modules.
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components.
import FlexibleContent from '../FlexibleContent';

// Utils.
import { scrollToAnchor, scrollToTop } from '../../utils/scrollTo';

// Styles.
import './Page.scss';

const Page = ({ data, location }) => {

    if (!data.acf.content) return null;

    useEffect(() => {
        if (location.hash) {
            scrollToAnchor(location.hash);
        } else {
            scrollToTop();
        }
    }, [location]);

    return (
        <div className="Page">
            <FlexibleContent blocks={ data.acf.content } />
        </div>
    );

};

Page.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Page;
