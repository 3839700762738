// Modules.
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import ACFLink from '../ACFLink';
import Button from '../Button';

// Constants.
import { ACF_LINK_TYPES, TABLET_PORTRAIT } from '../../utils/constants';

// Context.
import { StageContext } from '../../context';

// Styles.
import './VillainImage.scss';

const VillainImage = ({ data }) => {

    const context = useContext(StageContext);
    const hasBackgroundImage = context.width <= TABLET_PORTRAIT
        ? !!data.villain.mobile_image : !!data.villain.image;
    const backgroundColor = data.villain.bcolor ? data.villain.bcolor : 'gold';

    const classes = classnames({
        'VillainImage': true,
        'has-image': hasBackgroundImage,
        [backgroundColor]: !hasBackgroundImage,
    });

    const style = {};
    if (hasBackgroundImage) {
        const image = context.width <= TABLET_PORTRAIT ? data.villain.mobile_image.url : data.villain.image.url;
        style.backgroundImage = `url(${ image })`;
    }

    const renderButton = label => {
        return hasBackgroundImage
            ? <Button theme="cream" hollow={ false }>{ label }</Button>
            : backgroundColor === 'gold'
                ? <Button theme="cream" hollow={ true }>{ label }</Button>
                : <Button theme="gold" hollow={ true }>{ label }</Button>;
    };

    return (
        <div className={ classes } id={ data.anchor_id }>
            <div className="image" style={ style }>
                <div className="wrapper">
                    <h3>{ data.villain.header }</h3>
                    <p>{ data.villain.copy }</p>
                    { data.villain.link_type !== ACF_LINK_TYPES.NONE &&
                        <div className="buttons">
                            { data.villain.buttons.map((button, key) => (
                                <ACFLink data={ button } key={ key }>
                                    { renderButton(button.button_label) }
                                </ACFLink>
                            )) }
                        </div>
                    }
                </div>
            </div>
        </div>
    );

};

VillainImage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default VillainImage;
