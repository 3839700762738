// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import ACFLink from '../ACFLink';
import Button from '../Button';

// Context.
import { StageContext } from '../../context';

// Utils.
import { ACF_LINK_TYPES, TABLET_LANDSCAPE, DESKTOP_LARGE } from '../../utils/constants';

// Styles.
import './MatrixGallery.scss';

const Tile = ({ data }) => (
    <StageContext.Consumer>
        {({ width }) => {

            const bgUrl = ((width <= 600) || ((data.size === '1x1') && (width > TABLET_LANDSCAPE && width < DESKTOP_LARGE))) ? data.bimage_squ.url : data.bimage_lan.url;

            const classes = classnames({
                'Tile': true,
                [`size-${ data.size } ${ data.bcolor }`]: true,
                'no-content': !data.header && !data.copy,
            });

            return (
                <div
                    className={ classes }
                    style={{ backgroundImage: `url(${ bgUrl })` }}
                >
                    <div className="wrapper">
                        <h2>{ data.header }</h2>
                        <h6>{ data.copy }</h6>
                        { data.link_type !== ACF_LINK_TYPES.NONE &&
                            <ACFLink data={ data }>
                                <Button theme="cream" hollow={ data.bimage_lan.url === undefined }>{ data.button_label }</Button>
                            </ACFLink>
                        }
                    </div>
                </div>
            );

        }}
    </StageContext.Consumer>
);

const MatrixGallery = ({ data }) => (
    <div className="MatrixGallery">
        <div className="wrapper">
            {
                data.matrix_tiles.map((tile, key) => (
                    <Tile data={{ ...tile }} key={ key } />
                ))
            }
        </div>
    </div>
);

MatrixGallery.propTypes = {
    data: PropTypes.object.isRequired,
};

export default MatrixGallery;
