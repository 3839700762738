import scroll from 'scroll';
import scrollDoc from 'scroll-doc';

export const scrollToTop = () => {

    const scrollElement = scrollDoc();
    scroll.top(scrollElement, 0);

};

export const scrollToAnchor = (anchor) => {

    const element = document.querySelector(anchor);

    if (!element) return;

    const scrollElement = scrollDoc();

    const HEADER_HEIGHT = 146;
    const scrollToPosition = window.scrollY + element.getBoundingClientRect().top;
    const position = (scrollToPosition - HEADER_HEIGHT) < 0
        ? 0 : (scrollToPosition - HEADER_HEIGHT);

    scroll.top(scrollElement, position);

};
