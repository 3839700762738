// Modules.
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import ACFLink from '../ACFLink';
import Button from '../Button';
import DangerousCopy from '../DangerousCopy';
import NewsPagination from './elements/NewsPagination';
import NewsPreview from './elements/NewsPreview';

// API.
import { getNewsData } from '../../api/news';

// Styles.
import './NewsSection.scss';

const NewsSection = ({ data, updatePageLoadCounter }) => {

    const NEWS_PER_PAGE = 12;

    const [ loading, setLoading ] = useState(false);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ news, setNews ] = useState({
        data: null,
        totalPages: null,
        total: null,
    });

    const load = async (page = 1) => {
        updatePageLoadCounter(1);
        setLoading(true);

        const count = data.is_preview ? parseInt(data.count) : null;
        const [ newsData, totalPages, total ] = count ? await getNewsData(count) : await getNewsData(NEWS_PER_PAGE, page);

        setNews({ totalPages, total, data: newsData });
        setCurrentPage(page);
        setLoading(false);
        updatePageLoadCounter(-1);
    };

    useEffect(() => {
        load();
    }, []);

    if (!news.data || loading) {
        return null;
    }

    const hasPagination = !data.is_preview && news.total > NEWS_PER_PAGE;
    const previewClasses = classnames({
        'previews': true,
        'has_pagination': hasPagination,
    });

    return (
        <div className="NewsSection">
            <div className="wrapper">
                { data.header && <h5>{ data.header }</h5> }
                { data.copy && <DangerousCopy copy={ data.copy } /> }

                { (news.data && !loading) &&
                    <div className={ previewClasses }>
                        { news.data.map((post, key) => (<NewsPreview key={ key } data={ post } />)) }

                        { data.button_label &&
                            <ACFLink data={ data }>
                                <Button theme="gold" hollow={ true }>{ data.button_label }</Button>
                            </ACFLink>
                        }

                        { hasPagination &&
                            <NewsPagination
                                current={ currentPage }
                                max={ news.totalPages }
                                onClick={ index => { load(index); } }
                                onNext={ () => { load(currentPage + 1); } }
                            />
                        }
                    </div>
                }
            </div>
        </div>
    );

};

NewsSection.propTypes = {
    data: PropTypes.object.isRequired,
    updatePageLoadCounter: PropTypes.func.isRequired,
};

export default NewsSection;
