// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import MenuLink from '../../../MenuLink';

// Styles.
import './LinkDropdown.scss';

const LinkDropdown = ({ hovered, item, updateHoverState }) => {

    const classes = classnames({
        'LinkDropdown': true,
        'visible': hovered,
    });

    return (
        <div
            className={ classes }
            onMouseEnter={ () => { updateHoverState(true) } }
            onMouseLeave={ () => { updateHoverState(false) } }
        >
            { item.__children.map(
                (child, key) => (
                    <MenuLink data={ child } key={ key }>
                        <div dangerouslySetInnerHTML={ { __html: child.title } } />
                    </MenuLink>
                )
            ) }
        </div>
    );
}

LinkDropdown.propTypes = {
    hovered: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    updateHoverState: PropTypes.func.isRequired,
};

export default LinkDropdown;
