// Modules.
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import moment from 'moment';

// Components.
import Button from '../../components/Button';
import DangerousCopy from '../../components/DangerousCopy';

// API.
import { getNewsDataById, shareArticle } from '../../api/news';

// Utils.
import { convertToRelativeURL } from '../../utils/menu';
import { scrollToTop } from '../../utils/scrollTo';

// Styles.
import './NewsArticle.scss';

const NewsArticle = ({ gvars, match, updatePageLoadCounter }) => {

    const [ data, setData ] = useState(null);
    const [ email, setEmail ] = useState('');
    const [ emailMessage, setEmailMessage ] = useState('');
    const [ showInput, setShowInput ] = useState(false);

    const id = match.params.id;
    const load = async () => {
        updatePageLoadCounter(1);

        const article = await getNewsDataById(id);
        setData(article);
        scrollToTop();

        updatePageLoadCounter(-1);
    };

    useEffect(() => {
        load();
    }, []);

    if (!data) {
        return null;
    }

    const url = window.location.href;
    const twitterUrl = `${ data.title.rendered } ${ url }`;

    const shareThisArticle = () => {
        shareArticle(email, data.title.rendered, url).then(response => {
            setEmail('');
            setEmailMessage(response.data.message);
        });
    };

    return (
        <div className="NewsArticle">
            <div className="wrapper">
                <h5 className="header">{ data.title.rendered }</h5>

                <h5 className="date">{ moment(data.date).format("MMMM D, YYYY") }</h5>

                { data.acf.source &&
                    <h6 className="source">Source: { data.acf.source }</h6>
                }

                <div className="copy">
                    <DangerousCopy copy={ data.content.rendered } />

                    { data.acf.author &&
                        <h6 className="author">{ data.acf.author }</h6>
                    }
                </div>

                <div className="share">
                    <h5>Do you know someone else who would enjoy this story?</h5>

                    <ul>
                        <li>
                            <a
                                href={ `https://twitter.com/intent/tweet?text=${ twitterUrl }` }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="/assets/images/icon_share-twitter.png" alt="Twitter Icon" />
                            </a>
                        </li>
                        <li>
                            <a
                                href={ `https://www.facebook.com/sharer/sharer.php?u=${ url }` }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="/assets/images/icon_share-facebook.png" alt="Facebook Icon" />
                            </a>
                        </li>
                        <li>
                            <span onClick={ () => { setShowInput(!showInput); } }>
                                <img src="/assets/images/icon_share-email.png" alt="Email Icon" />
                            </span>
                        </li>
                    </ul>

                    { showInput &&
                        <div className="form-container">
                            <div className="form-input">
                                <input
                                    type="email"
                                    value={ email }
                                    placeholder="Email*"
                                    onChange={ event => { setEmail(event.target.value); } }
                                />
                            </div>

                            <span
                                className={ classnames({ 'disabled': !email }) }
                                onClick={ () => { shareThisArticle(); } }
                            >
                                <Button theme="gold" hollow={ true }>
                                    Share
                                </Button>
                            </span>

                            { emailMessage && <span className="email-message">{ emailMessage }</span> }
                        </div>
                    }

                </div>

                { gvars.news_page &&
                    <div className="buttons">
                        <Link to={ convertToRelativeURL(gvars.news_page) }>
                            <Button theme="gold" hollow={ true }>Back to News</Button>
                        </Link>
                    </div>
                }
            </div>
        </div>
    );

};

NewsArticle.propTypes = {
    gvars: PropTypes.object,
    updatePageLoadCounter: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
};

NewsArticle.defaultProps = {
    gvars: {},
};

export default NewsArticle;
