// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import AdditionalResources from '../AdditionalResources';
import AlignedCopyList from '../AlignedCopyList';
import BlogPreview from '../BlogPreview';
import BlockBackground from '../BlockBackground';
import ContactForm from '../ContactForm';
import Downloadables from '../Downloadables';
import DownloadablesCondensed from '../DownloadablesCondensed';
import FloatingSection from '../FloatingSection';
import HeroCarousel from '../HeroCarousel';
import HeroCopy from '../HeroCopy';
import HeroImage from '../HeroImage';
import ImageBlock from '../ImageBlock';
import ListItOut from '../ListItOut';
import MatrixGallery from '../MatrixGallery';
import NewsSection from '../NewsSection';
import QuickInfo from '../QuickInfo';
import SearchResults from '../SearchResults';
import ShowLinksSection from '../ShowLinksSection';
import ShowMoreList from '../ShowMoreList';
import ShowMoreSection from '../ShowMoreSection';
import SidekickImage from '../SidekickImage';
import Timeline from '../Timeline';
import VillainImage from '../VillainImage';
import WriteItOut from '../WriteItOut';

// Styles.
import './FlexibleContent.scss';

// Constants.
import { ACF_LAYOUT_IDS } from '../../utils/constants';

const FlexibleContent = ({ blocks }) => {

    // Switcher to render the appropriate React Component
    // based on the ACF layout identifier.
    const getComponent = (block) => {

        switch (block.acf_fc_layout) {
            case ACF_LAYOUT_IDS.ADDITIONAL_RESOURCES_SECTION:
                return <AdditionalResources data={ block } />;
            case ACF_LAYOUT_IDS.ALIGNED_COPY_LIST:
                return <AlignedCopyList data={ block } />;
            case ACF_LAYOUT_IDS.BLOG_PREVIEW:
                return <BlogPreview data={ block } />;
            case ACF_LAYOUT_IDS.CONTACT_FORM:
                return <ContactForm data={ block } />;
            case ACF_LAYOUT_IDS.DOWNLOADABLES:
                return <Downloadables data={ block } />;
            case ACF_LAYOUT_IDS.DOWNLOADABLES_CONDENSED:
                return <DownloadablesCondensed data={ block } />;
            case ACF_LAYOUT_IDS.FLOATING_SECTION:
                return <FloatingSection data={ block } />;
            case ACF_LAYOUT_IDS.HERO_CAROUSEL:
                return <HeroCarousel data={ block } />;
            case ACF_LAYOUT_IDS.HERO_COPY:
                return <HeroCopy data={ block } />;
            case ACF_LAYOUT_IDS.HERO_IMAGE:
                return <HeroImage data={ block } />;
            case ACF_LAYOUT_IDS.IMAGE_BLOCK:
                return <ImageBlock data={ block } />;
            case ACF_LAYOUT_IDS.LIST_IT_OUT:
                return <ListItOut data={ block } />;
            case ACF_LAYOUT_IDS.MATRIX_GALLERY:
                return <MatrixGallery data={ block } />;
            case ACF_LAYOUT_IDS.NEWS_SECTION:
                return <NewsSection data={ block } />;
            case ACF_LAYOUT_IDS.QUICK_INFO:
                return <QuickInfo data={ block } />;
            case ACF_LAYOUT_IDS.SEARCH_RESULTS:
                return <SearchResults data={ block } />;
            case ACF_LAYOUT_IDS.SHOW_LINKS_SECTION:
                return <ShowLinksSection data={ block } />;
            case ACF_LAYOUT_IDS.SHOW_MORE_LIST:
                return <ShowMoreList data={ block } />;
            case ACF_LAYOUT_IDS.SHOW_MORE_SECTION:
                return <ShowMoreSection data={ block } />;
            case ACF_LAYOUT_IDS.SIDEKICK_IMAGE:
                return <SidekickImage data={ block } />;
            case ACF_LAYOUT_IDS.TIMELINE:
                return <Timeline data={ block } />;
            case ACF_LAYOUT_IDS.VILLAIN_IMAGE:
                return <VillainImage data={ block } />;
            case ACF_LAYOUT_IDS.WRITE_IT_OUT:
                return <WriteItOut data={ block } />;
            default:
                return;
        }

    };

    return (
        <section className="FlexibleContent">
            { blocks.map((block, key) => {
                return (
                    <div key={ key } id={ block.anchor_id } className={ block.acf_fc_layout }>
                        { getComponent(block) }
                        <BlockBackground data={ block } />
                    </div>
                );
            }) }
        </section>
    );

};

FlexibleContent.propTypes = {
    blocks: PropTypes.array.isRequired,
};

export default FlexibleContent;
