// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import ArrowIcon from '../../../ArrowIcon';

// Styles.
import './NewsPagination.scss';

const NewsPagination = ({ current, max, onClick, onNext }) => (
    <div className="NewsPagination">
        { current > 1 &&
            <div className="prev" onClick={ () => { onClick(current - 1) } }>
                <ArrowIcon theme="gold" left={ true } /> <span>Previous Page</span>
            </div>
        }

        { [ ...Array(max) ].map((value, key) => (
            <div
                key={ key }
                className={ classnames({ 'number': true, 'active': current === key + 1 }) }
                onClick={ () => { onClick(key + 1); } }
            >
                { key + 1 }
            </div>
        )) }
        { current < max &&
            <div className="next" onClick={ () => { onClick(current + 1) } }>
                <span>Next Page</span> <ArrowIcon theme="gold" right={ true } />
            </div>
        }
    </div>
);

NewsPagination.propTypes = {
    current: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
};

export default NewsPagination;
