// Modules.
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Constants.
import { TABLET_PORTRAIT } from '../../utils/constants';

// Context.
import { StageContext } from '../../context';

// Styles.
import './BlockBackground.scss';

const BlockBackground = ({ data }) => {

    if (!data.bimage_block && !data.bimage_block_mobile) return null;

    const context = useContext(StageContext);
    const classes = classnames({
        'BlockBackground': true,
    });

    if (context.width < TABLET_PORTRAIT) {
        return data.bimage_block_mobile
            ? <img className={ classes } src={ data.bimage_block_mobile.url } alt={ data.bimage_block_mobile.title } />
            : null;
    }

    return data.bimage_block
        ? <img className={ classes } src={ data.bimage_block.url } alt={ data.bimage_block.title } />
        : null;
    ;

};

BlockBackground.propTypes = {
    data: PropTypes.object.isRequired,
};

export default BlockBackground;
