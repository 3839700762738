// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Constants.
import { WP_QUERY_IDS } from '../../../../utils/constants';

// Styles.
import './FooterSocialMenu.scss';

const FooterSocialMenu = ({ links, socialMedias, title }) => (
    <div className="FooterSocialMenu">
        <h6>{ title }</h6>
        { links.map((link, key) => {
            if (link.object !== WP_QUERY_IDS.SOCIAL_MEDIAS) return null;

            const sm = socialMedias.find(s => s.id === parseInt(link.object_id));
            if (!sm) return null;

            return (
                <a
                    href={ sm.acf.link.url }
                    target="_blank"
                    rel="noopener noreferrer"
                    key={ key }
                >
                    <div className="social" key={ key }>
                        { sm.acf.icon && <img src={ sm.acf.icon.url } alt={ sm.title.rendered } /> }
                        <h6>{ sm.title.rendered }</h6>
                    </div>
                </a>
            );
        }) }
    </div>
);

FooterSocialMenu.propTypes = {
    links: PropTypes.array.isRequired,
    socialMedias: PropTypes.array,
    title: PropTypes.string.isRequired,
};

FooterSocialMenu.defaultProps = {
    socialMedias: [],
};

export default FooterSocialMenu;
