// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import DangerousCopy from '../DangerousCopy';

// Styles.
import './AdditionalResources.scss';

const Block = ({ info }) => {

    const classes = classnames({
        'Info': true,
        'mobile-only': info.is_mobile_bcolor,
        'no-header': !info.header,
    });

    return (
        <div className={ classes }>
            { info.header && <h2>{ info.header }</h2> }
            <DangerousCopy copy={ info.copy } />
        </div>
    );

};

const AdditionalResources = ({ data }) => {

    const classes = classnames({
        'AdditionalResources': true,
    });

    return (
        <div className={ classes }>
            <div className="wrapper">
                {
                    data.additional_resources.map((info, key) => (
                        <Block info={ info } key={ key } />
                    ))
                }
            </div>
        </div>
    );

};

AdditionalResources.propTypes = {
    data: PropTypes.object.isRequired,
};

export default AdditionalResources;
