// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import FadForm from '../../../FadForm';

// Styles.
import './FadDropdown.scss';

const FadDropdown = ({ hovered, updateHoverState }) => {

    const classes = classnames({
        'FadDropdown': true,
        'visible': hovered,
    });

    return (
        <div
            className={ classes }
            onMouseEnter={ () => { updateHoverState(true) } }
            onMouseLeave={ () => { updateHoverState(false) } }
        >
            <FadForm />
        </div>
    );

};

FadDropdown.propTypes = {
    hovered: PropTypes.bool,
    updateHoverState: PropTypes.func.isRequired,
};

FadDropdown.defaultProps = {
    hovered: false,
};

export default FadDropdown;
