// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import ACFLink from '../ACFLink';
import Button from '../Button';
import WriteItOut from '../WriteItOut';

// Utils.
import { ACF_LINK_TYPES } from '../../utils/constants';

// Styles.
import './ShowLinksSection.scss';

const ShowLink = ({ data }) => (
    <div className="ShowLink">
        <h5>{ data.header }</h5>
        <p>{ data.copy }</p>
        { data.link_type !== ACF_LINK_TYPES.NONE &&
            <ACFLink data={ data }>
                <Button theme="gold" hollow={ true }>{ data.button_label }</Button>
            </ACFLink>
        }
    </div>
);

const ShowLinksSection = ({ data }) => (
    <div className="ShowLinksSection">
        <WriteItOut data={ data } />
        <div className="show-links-section-container">
            <div className="wrapper">
                { data.links &&
                    <div className="links-container">
                        { data.links.map((link, key) => (
                            <ShowLink data={ link } key={ key } />
                        )) }
                    </div>
                }
            </div>
        </div>
    </div>
);

ShowLinksSection.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ShowLinksSection;
