// Modules.
import axios from 'axios';

// Constants.
import { WP_QUERY_IDS } from '../utils/constants';

// Config.
const host = process.env.REACT_APP_API_URL;
const base = process.env.REACT_APP_API_PATH;

async function getAnchorLinksData() {
    return axios.get(`${ host }${ base }${ WP_QUERY_IDS.ANCHOR_LINK }&per_page=100`).then(res => {
        return res.data;
    });
}

async function getFindADoctorData() {
    return axios.get(`${ host }${ base }${ WP_QUERY_IDS.FIND_A_DOCTOR }&per_page=100`).then(res => {
        return res.data;
    });
}

async function getFooterMenuData() {
    return axios.get(`${ host }${ base }menus/footer&per_page=100`).then(res => {
        return res.data;
    });
}

async function getLegalMenuData() {
    return axios.get(`${ host }${ base }menus/legal&per_page=100`).then(res => {
        return res.data;
    });
}

async function getPrimaryMenuData() {
    return axios.get(`${ host }${ base }menus/primary&per_page=100`).then(res => {
        return res.data;
    });
}

async function getSocialMenuData() {
    return axios.get(`${ host }${ base }menus/social&per_page=100`).then(res => {
        return res.data;
    });
}

async function getSocialMediasData() {
    return axios.get(`${ host }${ base }${ WP_QUERY_IDS.SOCIAL_MEDIAS }&per_page=100`).then(res => {
        return res.data;
    });
}

export {
    getAnchorLinksData,
    getFindADoctorData,
    getFooterMenuData,
    getLegalMenuData,
    getPrimaryMenuData,
    getSocialMenuData,
    getSocialMediasData,
};
export default getPrimaryMenuData;
