// Modules.
import React from 'react';

// Components.
import FadForm from '../../../FadForm';

// Styles.
import './FadExpandMenu.scss';

const FadExpandMenu = () => {

    return (
        <div className="FadExpandMenu">
            <FadForm theme="cream" />
        </div>
    );

};

export default FadExpandMenu;
