// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import Radio from './elements/Radio';

// Styles.
import './RadioInput.scss';

const RadioInput = ({ onChange, options, value }) => {

    return (
        <div className="RadioInput">
            { options.map((option, key) => (
                <Radio
                    key={ key }
                    value={ option }
                    onClick={ () => onChange(key) }
                    selected={ option === value }
                />
            )) }
        </div>
    );

};

RadioInput.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

RadioInput.defaultProps = {
    onChange: f => f,
    options: [],
};

export default RadioInput;
