// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles.
import './ArrowIcon.scss';

const ArrowIcon = ({ theme, up, down, left, right }) => (
    <svg
        className={
            classnames({
                'ArrowIcon': true,
                [theme]: theme,
                'up': up,
                'down': down,
                'left': left,
                'right': right,
            })
        }
        width="11px"
        height="17px"
        viewBox="0 0 11 17"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <text
            stroke="none"
            strokeWidth={ 1 }
            fillRule="evenodd"
            fontFamily="TTNorms-Bold, TT Norms"
            fontSize={ 19 }
        >
            <tspan x={ -4 } y={ 15 }>
            ↑
            </tspan>
        </text>
    </svg>
);

ArrowIcon.propTypes = {
    theme: PropTypes.string,
    up: PropTypes.bool,
    down: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
};

ArrowIcon.defaultProps = {
    theme: null,
    up: false,
    down: false,
    left: false,
    right: false,
};

export default ArrowIcon;
