// Modules.
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import MenuLink from '../../../MenuLink';

// Context.
import { HeaderMobileContext } from '../../../../context';

// Styles.
import './LinkExpandMenu.scss';

const LinkExpandMenu = ({ item }) => {

    const context = useContext(HeaderMobileContext);
    const classes = classnames({
        'LinkExpandMenu': true,
    });

    return (
        <div className={ classes } onClick={ () => { context.setOpened(false); }}>
            { item.__children.map(
                (child, key) => (
                    <MenuLink data={ child } key={ key } >
                        <div dangerouslySetInnerHTML={ { __html: child.title } } />
                    </MenuLink>
                )
            ) }
        </div>
    );

};

LinkExpandMenu.propTypes = {
    item: PropTypes.object.isRequired,
};

export default LinkExpandMenu;
