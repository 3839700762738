// Modules.
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles.
import './HeaderMobileLogoBar.scss';

const HeaderMobileLogoBar = ({ gvars, onToggle, opened }) => (
    <div className="HeaderMobileLogoBar">
        <Link to="/" onClick={ () => onToggle(false) }>
            { gvars.header_logo &&
                <img src={ gvars.header_logo.url } alt="Iron Road Logo" />
            }
        </Link>

        <div
            className={ classnames({ 'hamburglar': true, 'open': opened }) }
            onClick={ () => onToggle() }
        >
            <div className="patty" />
            <div className="patty" />
            <div className="patty" />
        </div>
    </div>
);

HeaderMobileLogoBar.propTypes = {
    gvars: PropTypes.object,
    onToggle: PropTypes.func.isRequired,
    opened: PropTypes.bool,
};

HeaderMobileLogoBar.defaultProps = {
    gvars: {},
    opened: false,
};

export default HeaderMobileLogoBar;
