import {
    // Global.
    STORE_GLOBAL_VARIABLES,

    // Loading.
    UPDATE_INIT_LOAD_COUNTER,
    UPDATE_PAGE_LOAD_COUNTER,

    // Menu.
    STORE_ANCHOR_LINKS_DATA,
    STORE_FAD_DATA,
    STORE_FOOTER_MENU_DATA,
    STORE_LEGAL_MENU_DATA,
    STORE_PRIMARY_MENU_DATA,
    STORE_SOCIAL_MENU_DATA,
    STORE_SOCIAL_MEDIAS_DATA,

    // Modal.
    CLOSE_MODAL,
    OPEN_MODAL,

    // Search.
    EMPTY_SEARCH_RESULTS,
    STORE_SEARCH_RESULTS,
    STORE_SEARCH_VALUE,
} from './types';

// Global.
export const storeGlobalVariables = data => ({
    type: STORE_GLOBAL_VARIABLES,
    payload: { data },
});

// Loading.
export const updateInitLoadCounter = data => ({
    type: UPDATE_INIT_LOAD_COUNTER,
    payload: { data },
});

export const updatePageLoadCounter = data => ({
    type: UPDATE_PAGE_LOAD_COUNTER,
    payload: { data },
});

// Menu.
export const storeAnchorLinksData = data => ({
    type: STORE_ANCHOR_LINKS_DATA,
    payload: { data },
});

export const storeFadData = data => ({
    type: STORE_FAD_DATA,
    payload: { data },
});

export const storeFooterMenuData = data => ({
    type: STORE_FOOTER_MENU_DATA,
    payload: { data },
});

export const storeLegalMenuData = data => ({
    type: STORE_LEGAL_MENU_DATA,
    payload: { data },
});

export const storePrimaryMenuData = data => ({
    type: STORE_PRIMARY_MENU_DATA,
    payload: { data },
});

export const storeSocialMenuData = data => ({
    type: STORE_SOCIAL_MENU_DATA,
    payload: { data },
});

export const storeSocialMediasData = data => ({
    type: STORE_SOCIAL_MEDIAS_DATA,
    payload: { data },
});

// Modal.
export const closeModal = () => ({
    type: CLOSE_MODAL,
});

export const openModal = (modalType, data = null) => ({
    type: OPEN_MODAL,
    payload: { modalType, data },
});

// Search.
export const emptySearchResults = () => ({
    type: EMPTY_SEARCH_RESULTS,
});

export const storeSearchResults = data => ({
    type: STORE_SEARCH_RESULTS,
    payload: { data },
});

export const storeSearchValue = data => ({
    type: STORE_SEARCH_VALUE,
    payload: { data },
});
