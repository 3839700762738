// Modules.
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components.
import HeaderMobile from './HeaderMobile';

// Store.
const mapStateToProps = state => ({
    gvars: state.global.gvars,
    primary: state.menu.primary,
});

export default withRouter(
    connect(mapStateToProps)(HeaderMobile)
);
