// Modules.
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components.
import ArrowIcon from '../ArrowIcon';
import FooterMenu from './elements/FooterMenu';
import FooterSocialMenu from './elements/FooterSocialMenu';

// Utils.
import { scrollToTop } from '../../utils/scrollTo';

// Styles.
import './Footer.scss';

const Footer = ({ footer, gvars, legal, socials }) => (
    <div className="Footer">
        <div className="scroll-top" onClick={ scrollToTop }>
            <ArrowIcon up={ true } theme="cream" />
        </div>

        <div className="logo">
            <Link to="/">
                <div className="wrapper">
                    { gvars.footer_logo &&
                        <img src={ gvars.footer_logo.url } alt="Stroke Seal Gold" />
                    }
                    { gvars.footer_logo_text &&
                        <div>{ gvars.footer_logo_text }</div>
                    }
                </div>
            </Link>
        </div>

        <div className="footer-menus">
            { footer.items && <FooterMenu key={ 0 } title={ footer.name } links={ footer.items } /> }
            { legal.items && <FooterMenu key={ 1 } title={ legal.name } links={ legal.items } /> }
            { socials.items && <FooterSocialMenu key={ 2 } title={ socials.name } links={ socials.items } /> }
        </div>
    </div>
);

Footer.propTypes = {
    gvars: PropTypes.object,
    footer: PropTypes.object,
    legal: PropTypes.object,
    social: PropTypes.object,
};

Footer.defaultProps = {
    gvars: {},
    footer: null,
    legal: null,
    social: null,
};

export default Footer;

