// Modules.
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import axios from 'axios';
import debounce from 'lodash.debounce';
import qs from 'qs';
import Url from 'url-parse';

// Components.
import Loader from '../../components/Loader';
import AppWithRouter from './elements/AppWithRouter';

// Utils.
import { StageContext } from '../../context';

// Styles.
import './App.scss';

const App = ({ gvars, initLoad, pageLoad }) => {

    const [ ready, setReady ] = useState(false);
    const [ stage, setStage ] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {

        // Check for Preview query string
        const currentUrl = new Url(window.location.href);
        if (currentUrl.query) {
            const params = qs.parse(currentUrl.query, { ignoreQueryPrefix: true });
            // For whatever reason, Wordpress gets a CORS error if this query stays...
            delete params.preview_nonce;
            axios.defaults.params = params;
        }

        window.addEventListener('resize',
            debounce(() => setStage({
                width: window.innerWidth,
                height: window.innerHeight,
            }), 100)
        );

        setReady(true);

        return () => {
            window.removeEventListener('resize', () => {});
        };

    }, []);

    if (!gvars) gvars = {};

    const classes = classnames({
        'App': true,
        [`theme-${gvars.theme_color}`]: gvars.theme_color,
        'has-header-tag': !!gvars.header_tag,
    });

    return (
        <div className={ classes }>
            <StageContext.Provider value={ stage }>
                <Loader active={ !ready || initLoad > 0 || pageLoad > 0 } />

                <Router>
                    { ready ? <AppWithRouter /> : null }
                </Router>
            </StageContext.Provider>
        </div>
    );
};

App.propTypes = {
    gvars: PropTypes.object,
    initLoad: PropTypes.number.isRequired,
    pageLoad: PropTypes.number.isRequired,
};

App.defaultProps = {
    gvars: {},
};

export default App;
