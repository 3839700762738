// Modules.
import axios from 'axios';

// Config.
const host = process.env.REACT_APP_API_URL;
const base = process.env.REACT_APP_API_PATH;

async function getAllPagesData() {
    return axios.get(`${ host }${ base }pages&per_page=100`).then(res => {
        return res.data;
    });
}

async function getGlobalVariables() {
    return axios.get(`${ host }${ base }variables`).then(res => {
        return res.data ? res.data[0].acf : null;
    });
};

async function getPageData(id) {
    return axios.get(`${ host }${ base }pages/${ id }`).then(res => {
        return res.data;
    });
}

export { getAllPagesData, getGlobalVariables, getPageData };
export default getPageData;
