// Modules.
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

// Components.
import ArrowIcon from '../../../ArrowIcon/ArrowIcon';
import DangerousCopy from '../../../DangerousCopy';

// Styles.
import './NewsPreview.scss';

const NewsPreview = ({ data }) => (
    <div className="NewsPreview">
        <h5>{ moment(data.date).format("MM/DD/YYYY") }</h5>
        <div>
            <Link to={ `/news-article/${ data.id }` }>
                <h5>{ data.title.rendered }</h5>
            </Link>
            <DangerousCopy copy={ data.excerpt.rendered } />

            <Link to={ `/news-article/${ data.id }` }>
                <div className="learn-more">
                    <span>Learn more</span> <ArrowIcon theme="gold" right={ true } />
                </div>
            </Link>
        </div>
    </div>
);

NewsPreview.propTypes = {
    data: PropTypes.object.isRequired,
};

export default NewsPreview;
