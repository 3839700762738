// Modules.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import Button from '../Button';
import DangerousCopy from '../DangerousCopy';

// Utils.
import { scrollToTop } from '../../utils/scrollTo';

// API.
import sendContactEmail from '../../api/contact';

// Styles.
import './ContactForm.scss';

const ContactForm = ({ data }) => {

    const DEFAULT_FORM_DATA = {
        name: '',
        email: '',
        phone: '',
        sendTo: '',
        subjectId: null,
        message: '',
    };

    const DEFAULT_FORM_VALIDITY = { ...DEFAULT_FORM_DATA };
    for (let key in DEFAULT_FORM_VALIDITY) {
        DEFAULT_FORM_VALIDITY[key] = true;
    }

    const [ selectOpen, setSelectOpen ] = useState(false);
    const [ formData, setFormData ] = useState({ ...DEFAULT_FORM_DATA });
    const [ formError, setFormError ] = useState(null);
    const [ formSubmitted, setFormSubmitted ] = useState(false);
    const [ formValid, setFormValid ] = useState({ ...DEFAULT_FORM_VALIDITY });

    const classes = classnames({
        'ContactForm': true,
    });

    const setFormValue = (key, value) => {
        setFormData({ ...formData, [key]: value });
    };

    const setSubject = (key) => {
        setFormData({ ...formData, subjectId: key, sendTo: data.subjects[key].email_to });
        setSelectOpen(false);
    };

    const sendEmail = () => {

        if (validateForm()) {
            setFormError(null);
            sendContactEmail(formData).then(res => {
                if (res.data.status !== 200) {
                    setFormError(res.data.message);
                } else {
                    setFormData({ ...DEFAULT_FORM_DATA });
                    setFormSubmitted(true);
                }
                scrollToTop();
            });
        } else {
            setFormError('*Please fill out all required fields');
        }

    };

    const validateForm = () => {

        setFormValid({
            name: !!formData.name,
            email: !!formData.email,
            subjectId: formData.subjectId !== null,
            message: !!formData.message,
        });

        return formData.name && formData.email && formData.subjectId !== null && formData.message;

    };

    return (
        <div className={ classes }>

            { !formSubmitted &&
                <div className="wrapper">
                    <h5>{ data.header }</h5>
                    <DangerousCopy copy={ data.copy } />

                    <div className="form-container">

                        { formError &&
                            <div className="form-error">
                                { formError }
                            </div>
                        }

                        <div className="form-group col-2">
                            <div className={ classnames({ 'form-input': true, 'error': !formValid.name }) }>
                                <input
                                    type="text"
                                    value={ formData.name }
                                    placeholder="Name*"
                                    onChange={ event => { setFormValue('name', event.target.value); } }
                                />
                            </div>
                        </div>

                        <div className="form-group col-2">
                            <div className={ classnames({ 'form-input': true, 'error': !formValid.email }) }>
                                <input
                                    type="email"
                                    value={ formData.email }
                                    placeholder="Email*"
                                    onChange={ event => { setFormValue('email', event.target.value); } }
                                />
                            </div>

                            <div className="form-input">
                                <input
                                    type="tel"
                                    value={ formData.phone }
                                    placeholder="Phone (optional)"
                                    onChange={ event => { setFormValue('phone', event.target.value); } }
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div
                                className={
                                    classnames({
                                        'form-input': true,
                                        'form-select': true,
                                        'error': !formValid.subjectId,
                                        'opened': selectOpen,
                                    })
                                }
                                onClick={ () => { setSelectOpen(!selectOpen); } }
                            >
                                <div className="input">
                                    { formData.subjectId !== null
                                        ? <span>{ data.subjects[formData.subjectId].subject_line }</span>
                                        : <span className="placeholder">Select Subject*</span> }
                                </div>
                                <div className="submitter">
                                    <span>→</span>
                                </div>

                            </div>

                            { selectOpen &&
                                <div className="options">
                                    { data.subjects.map((subject, key) => (
                                        <div key={ key } onClick={ () => { setSubject(key); } }>
                                            { subject.subject_line }
                                        </div>
                                    )) }
                                </div>
                            }
                        </div>

                        <div className="form-group">
                            <div className={ classnames({ 'form-input': true, 'error': !formValid.message }) }>
                                <textarea
                                    value={ formData.message }
                                    placeholder="Message*"
                                    onChange={ event => { setFormValue('message', event.target.value); } }
                                />
                            </div>
                        </div>

                        <div className="form-note">
                            *Indicates required field
                        </div>

                        <div className="form-submit" onClick={ () => sendEmail() }>
                            <Button theme="gold" hollow={ true }>
                                { data.submit_button_label }
                            </Button>
                        </div>
                    </div>

                </div>
            }

            { formSubmitted &&
                <div className="wrapper">
                    <h5>{ data.success_header }</h5>
                    <DangerousCopy copy={ data.success_copy } />

                    <div className="form-reset" onClick={ () => setFormSubmitted(false) }>
                        <Button theme="gold" hollow={ true }>
                            { data.reset_button_label }
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};

ContactForm.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ContactForm;
