// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './HoverHeader.scss';

const HoverHeader = ({ children, defaultClass, hoverClass }) => (
    <span className="HoverHeader">
        <span className={ defaultClass ? defaultClass : 'h8' }>{ children }</span>
        <span className={ hoverClass ? hoverClass : 'h9' }>{ children }</span>
    </span>
);

HoverHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
    ]).isRequired,
    defaultClass: PropTypes.string,
    hoverClass: PropTypes.string,
};

HoverHeader.defaultProps = {
    defaultClass: '',
    hoverClass: '',
};

export default HoverHeader;
