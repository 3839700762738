// Modules.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import Button from '../Button';
import RadioInput from '../RadioInput';

// Styles.
import './FadForm.scss';

const FadForm = ({ fad, theme, buttonTheme = false }) => {

    if (!fad || fad.length === 0) return null;

    const config = fad[0].acf;
    const questions = config.questions;

    const [ link, setLink ] = useState(null);
    const [ questionCount, setQuestionCount ] = useState(0);
    const [ answers, setAnswers ] = useState(
        [ ...Array(questions.length) ].fill(
            null, 0, questions.length
        )
    );

    const handleOnChange = (key, answerKey) => {
        if (answers[key] === answerKey) return;

        const newAnswers = [ ...answers ];
        newAnswers[key] = answerKey;

        const answerSettings = questions[key].que.answers[answerKey];
        if (answerSettings.action === 'next') {
            setQuestionCount(key + 1);
            setLink(null);
            newAnswers.fill(null, key + 1, questions.length);
        } else if (answerSettings.action === 'link') {
            setQuestionCount(key);
            setLink({ ...answerSettings.link });
        }

        setAnswers(newAnswers);
    };

    const classes = classnames({
        'FadForm': true,
        [theme]: theme,
    });

    return (
        <div className={ classes }>
            <div className="h8">{ config.copy }</div>
            { questions.map((question, key) => {
                if (key > questionCount) return null;

                return (
                    <div className="question" key={ key }>
                        <div className="h8">{ question.que.copy }</div>
                        <RadioInput
                            onChange={ value => handleOnChange(key, value) }
                            options={ question.que.answers.map(a => a.copy) }
                            value={ answers[key] !== null
                                ? question.que.answers[ answers[key] ].copy
                                : null
                            }
                        />
                    </div>
                );
            }) }
            { link &&
                <a href={ link.url } rel="noopener noreferrer" target="_blank">
                    { !buttonTheme && <div className="h9">{ link.title } →</div> }
                    { buttonTheme && <Button theme="cream" hollow={ true }>{ link.title }</Button> }
                </a>
            }
        </div>
    );

};

FadForm.propTypes = {
    fad: PropTypes.array,
    theme: PropTypes.string,
};

FadForm.defaultProps = {
    fad: null,
    theme: null,
};

export default FadForm;
