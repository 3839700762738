// Modules.
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components.
import AppWithRouter from './AppWithRouter';

// Store.
import {
    storeAnchorLinksData,
    storeFadData,
    storeFooterMenuData,
    storeGlobalVariables,
    storeLegalMenuData,
    storePrimaryMenuData,
    storeSocialMenuData,
    storeSocialMediasData,
    updateInitLoadCounter,
} from '../../../../redux/actions/app';

const mapDispatchToProps = {
    storeAnchorLinksData,
    storeFadData,
    storeFooterMenuData,
    storeGlobalVariables,
    storeLegalMenuData,
    storePrimaryMenuData,
    storeSocialMenuData,
    storeSocialMediasData,
    updateInitLoadCounter,
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(AppWithRouter)
);
