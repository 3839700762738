// Modules.
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components.
import Footer from '../../../../components/Footer';
import Header from '../../../../components/Header';
import HeaderMobile from '../../../../components/HeaderMobile';
import ModalContainer from '../../../../components/ModalContainer';
import NewsArticle from '../../../NewsArticle';
import Page from '../../../../components/Page';
import ReactGA from 'react-ga4';
import FeedbackIcon from '../../../../components/FeedbackIcon';

// API.
import {
    getAllPagesData,
    getGlobalVariables,
} from '../../../../api/core';

import {
    getAnchorLinksData,
    getFindADoctorData,
    getFooterMenuData,
    getLegalMenuData,
    getPrimaryMenuData,
    getSocialMenuData,
    getSocialMediasData,
} from '../../../../api/menus';

// Context.
import { StageContext } from '../../../../context';

// Utils.
import { convertToRelativeURL } from '../../../../utils/menu';

// Constants.
import { MENU_BREAK } from '../../../../utils/constants';

const getRouteComponentFromPages = (page, key) => {
    if (!page.link) return;
    return (
        <Route
            exact
            key={ key }
            path={ convertToRelativeURL(page.link) }
            render={ routeProps => (
                <Page
                    { ...routeProps }
                    data={ page }
                />
            ) }
        />
    );
};

const RenderApp = ({
    location,
    storeAnchorLinksData,
    storeFadData,
    storeFooterMenuData,
    storeGlobalVariables,
    storeLegalMenuData,
    storePrimaryMenuData,
    storeSocialMenuData,
    storeSocialMediasData,
    updateInitLoadCounter,
}) => {

    const [ init, setInit ] = useState(false);
    const [ loaded, setLoaded ] = useState(false);

    const [ pages, setPages ] = useState(null);
    const context = useContext(StageContext);

    const fetch = () => {
        return Promise.all([
            getAnchorLinksData(),
            getFindADoctorData(),
            getFooterMenuData(),
            getGlobalVariables(),
            getLegalMenuData(),
            getPrimaryMenuData(),
            getSocialMenuData(),
            getSocialMediasData(),
            getAllPagesData(),
        ]);
    };

    const load = async () => {
        updateInitLoadCounter(1);
        const dataset = await fetch();

        storeAnchorLinksData(dataset.shift());
        storeFadData(dataset.shift());
        storeFooterMenuData(dataset.shift());

        // Initialize Google Analytics
        const globals = dataset.shift();
        storeGlobalVariables(globals);
        if (globals.google_analytics_id) {
            ReactGA.initialize(globals.google_analytics_id);
            ReactGA.send({ hitType: "pageview", page: `${ window.location.pathname }${ window.location.search }`});
        }

        storeLegalMenuData(dataset.shift());
        storePrimaryMenuData(dataset.shift());
        storeSocialMenuData(dataset.shift());
        storeSocialMediasData(dataset.shift());

        setPages(dataset.shift());
    };

    useEffect(() => {
        if (!init && !pages) {
            setInit(true);
            load();
        }

        if (!loaded && pages) {
            setLoaded(true);
            updateInitLoadCounter(-1);
        }
    });

    if (!pages) {
        return null;
    }

    return (
        <React.Fragment>
            { context.width > MENU_BREAK
                ? <Header />
                : <HeaderMobile />
            }

            <Switch location={ location }>
                { pages && pages.map(getRouteComponentFromPages) }

                <Route exact path="/news-article/:id" component={ NewsArticle } />
                <Redirect to="/" />
            </Switch>

            <ModalContainer />

            <FeedbackIcon />

            <Footer />
        </React.Fragment>
    );

};

RenderApp.propTypes = {
    location: PropTypes.object,
    storeAnchorLinksData: PropTypes.func.isRequired,
    storeFadData: PropTypes.func.isRequired,
    storeFooterMenuData: PropTypes.func.isRequired,
    storeGlobalVariables: PropTypes.func.isRequired,
    storeLegalMenuData: PropTypes.func.isRequired,
    storePrimaryMenuData: PropTypes.func.isRequired,
    storeSocialMenuData: PropTypes.func.isRequired,
    storeSocialMediasData: PropTypes.func.isRequired,
    updateInitLoadCounter: PropTypes.func.isRequired,
};

RenderApp.defaultProps = {
    location: null,
};

export default RenderApp;
