import { createContext } from 'react';

export const HeaderMobileContext = createContext({
    opened: false,
    setOpened: f => f,
});

export const StageContext = createContext({
    width: 0,
    height: 0,
});
