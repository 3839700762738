// Modules.
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import ACFLink from '../ACFLink';
import Button from '../Button';
import DangerousCopy from '../DangerousCopy';

// Context.
import { StageContext } from '../../context';

// Utils.
import { ACF_LINK_TYPES, TABLET_PORTRAIT } from '../../utils/constants';

// Styles.
import './SidekickImage.scss';

const SidekickImage = ({ data }) => {

    const context = useContext(StageContext);
    const backgroundImage = data.sidekick.background_type.indexOf("image") !== -1;

    const classes = classnames({
        'SidekickImage': true,
        'has-margins': data.sidekick.has_margins,
        'has-image': !!backgroundImage,
        [data.sidekick.color]: !backgroundImage && data.sidekick.color,
    });

    const style = {};
    if (backgroundImage) {
        style.backgroundImage = `url(${ (context.width < TABLET_PORTRAIT && data.sidekick.mobile_image.url) ? data.sidekick.mobile_image.url : data.sidekick.image.url })`
    }

    const getButtonTheme = () => (
        (!backgroundImage && data.sidekick.color === "cream") ? "gold" : "cream"
    );

    return (
        <div className={ classes } id={ data.anchor_id }>
            <div className="image" style={ style }>
                <div className="wrapper">
                    <h2>{ data.sidekick.header }</h2>
                    <DangerousCopy copy={ data.sidekick.copy } />
                    { data.sidekick.link_type !== ACF_LINK_TYPES.NONE &&
                        <ACFLink data={ data.sidekick }>
                            <Button theme={ getButtonTheme() } hollow={ !backgroundImage }>
                                { data.sidekick.button_label }
                            </Button>
                        </ACFLink>
                    }
                </div>
            </div>
        </div>
    );

};

SidekickImage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default SidekickImage;
