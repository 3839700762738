// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './HeaderTagOverlay.scss';

const HeaderTagOverlay = ({ children }) => (
    <div className='HeaderTagOverlay'>
        { children }
    </div>
);

HeaderTagOverlay.propTypes = {
    children: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
};

export default HeaderTagOverlay;

