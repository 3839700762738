// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import ArrowIcon from '../../../ArrowIcon';

// Styles.
import './ModalCloseTrigger.scss';

const ModalCloseTrigger = ({ onClose }) => (
    <div className="ModalCloseTrigger" onClick={ onClose }>
        <div className="icon">
            <ArrowIcon theme="cream" right={ true }/>
        </div>
        <span>Close</span>
    </div>
);

ModalCloseTrigger.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ModalCloseTrigger;
