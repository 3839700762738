// Modules.
import { connect } from 'react-redux';

// Components.
import ACFLink from './ACFLink';

// Store.
import { openModal } from '../../redux/actions/app';

const mapStateToProps = state => ({
    anchors: state.menu.anchors,
});

const mapDispatchToProps = {
    openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ACFLink);
