// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import ArrowIcon from '../../../ArrowIcon';
import MenuLink from '../../../MenuLink';

// Styles.
import './HeaderSubmenu.scss';

const HeaderSubmenu = ({ data }) => (
    <div className="HeaderSubmenu">
        { data.map(
            (d, key) => (
                <MenuLink data={ d } key={ key }>
                    <ArrowIcon theme="cream" down />
                    <div dangerouslySetInnerHTML={ { __html: d.title } } />
                </MenuLink>
            )
        ) }
    </div>
);

HeaderSubmenu.propTypes = {
    data: PropTypes.array.isRequired,
};

export default HeaderSubmenu;
