// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

// Styles.
import './FeedbackIcon.scss';

const FeedbackIcon = ({gvars}) => (
    <Fragment>
        { gvars.feedback_icon_display && gvars.feedback_icon_link &&
            <a class="FeedbackIcon" href={gvars.feedback_icon_link} target="_blank" rel="noopener noreferrer">
                {gvars.feedback_icon_text}
            </a>
        }
    </Fragment>
);

FeedbackIcon.propTypes = {
    gvars: PropTypes.object,
};

FeedbackIcon.defaultProps = {
    gvars: {},
};

export default FeedbackIcon;