// Modules.
import React from 'react';

const SearchIcon = () => (
    <svg width='15' height='14' viewBox='0 0 15 14' xmlns='http://www.w3.org/2000/svg'>
        <g id='Medicare-Site-(Desktop)' fill='none' fillRule='evenodd'>
            <g id='(Medicare)-Find-A-Doc-Menu' transform='translate(-1251 -113)' stroke='#003042'>
                <g id='(Medicare)-Nav-Bar'>
                    <g id='Nav-Bar'>
                        <g id='Search' transform='translate(1252 105)'>
                            <g id='Icon_MagnifyingGlass_Blue' transform='translate(0 9)'>
                                <g id='Group-6'>
                                    <path d='M9,4.5 C9,6.98531707 6.98531707,9 4.5,9 C2.01468293,9 0,6.98531707 0,4.5 C0,2.01468293 2.01468293,0 4.5,0 C6.98531707,0 9,2.01468293 9,4.5 Z'
                                    id='Stroke-1' />
                                    <path d='M8,8 L13,13' id='Stroke-3' />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default SearchIcon;
