// Modules.
import axios from 'axios';
import qs from 'qs';

// Config.
const host = process.env.REACT_APP_API_URL;
const base = process.env.REACT_APP_API_PATH;

async function searchAll(config) {
    return Promise.all([
        searchMedia({ ...config, mime_type: 'application/pdf' }),
        searchPages({ ...config, per_page: 100 }),
    ]).then(res => {
        const medias = res.shift();
        const pages = res.shift();

        return medias.concat(pages);
    });
};

async function searchMedia(config) {
    const queryString = config ? `&${ qs.stringify(config) }` : '';
    return axios.get(`${ host }${ base }media${ queryString }`).then(res => {
        return res.data;
    });
};

async function searchPages(config) {
    const queryString = config ? `&${ qs.stringify(config) }` : '';
    return axios.get(`${ host }${ base }pages${ queryString }`).then(res => {
        return res.data;
    });
};

async function searchPosts(config) {
    const queryString = config ? `&${ qs.stringify(config) }` : '';
    return axios.get(`${ host }${ base }posts${ queryString }`).then(res => {
        return res.data;
    });
};

export { searchAll, searchMedia, searchPages, searchPosts };
export default searchPages;
