// Modules.
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles.
import './HeaderLogoBar.scss';

const HeaderLogoBar = ({ collapsed, gvars }) => {

    if (!gvars.header_logo) return null;

    const classes = classnames({
        'HeaderLogoBar': true,
        'collapsed': collapsed,
    });

    const ironRoadLogo = (collapsed && gvars.header_secondary_logo)
        ? gvars.header_secondary_logo.url
        : gvars.header_logo.url;

    return (
        <div className={ classes }>
            <Link to="/" className="iron-road-logo">
                <img src={ ironRoadLogo } alt="Iron Road Logo" />
            </Link>
        </div>
    )

};

HeaderLogoBar.propTypes = {
    collapsed: PropTypes.bool,
    gvars: PropTypes.object,
};

HeaderLogoBar.defaultProps = {
    collapsed: false,
    gvars: {},
};

export default HeaderLogoBar;
