// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import ArrowIcon from '../ArrowIcon';

// Styles.
import './ShowMoreToggle.scss';

const ShowMoreToggle = ({ onClick, showing, theme }) => (
    <div className={ classnames({ 'ShowMoreToggle': true, [theme]: theme }) } onClick={ onClick }>
        <span>{ `Show ${ !showing ? 'more' : 'less' }` }</span>
        <ArrowIcon theme="gold" up={ showing } down={ !showing } />
    </div>
);

ShowMoreToggle.propTypes = {
    onClick: PropTypes.func.isRequired,
    showing: PropTypes.bool,
    theme: PropTypes.oneOf(['blue', 'cream', 'gold', 'red', 'white']),
};

ShowMoreToggle.defaultProps = {
    showing: false,
    theme: 'gold',
};

export default ShowMoreToggle;
