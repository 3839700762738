// Modules.
import { connect } from 'react-redux';

// Components.
import BlogPreview from './BlogPreview';

// Store.
import { updatePageLoadCounter } from '../../redux/actions/app';

const mapStateToProps = state => ({
    gvars: state.global.gvars,
});

const mapDispatchToProps = {
    updatePageLoadCounter,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogPreview);
