// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Flickity from 'react-flickity-component';

// Components.
import ACFLink from '../ACFLink';
import Button from '../Button';

// Context.
import { StageContext } from '../../context';

// Utils.
import { ACF_LINK_TYPES, TABLET_PORTRAIT } from '../../utils/constants';

// Styles.
import './HeroCarousel.scss';

const Slide = ({ slide }) => (
    <StageContext.Consumer>
        {({ width }) => (
            <div
                className={ classnames({ 'Slide': true, 'button-slide': slide.link_type !== ACF_LINK_TYPES.NONE }) }
                style={{ backgroundImage: `url(${ (width < TABLET_PORTRAIT && slide.mobile_image.url) ? slide.mobile_image.url : slide.image.url })` }}
            >
                <div className="wrapper">
                    <h2>{ slide.header }</h2>
                    <h6>{ slide.subheader }</h6>
                    { slide.link_type !== ACF_LINK_TYPES.NONE &&
                        <ACFLink data={ slide }>
                            <Button theme="cream" hollow={ false }>{ slide.button_label }</Button>
                        </ACFLink>
                    }
                </div>
            </div>
        )}
    </StageContext.Consumer>
);

class HeroCarousel extends Component {

    static get propTypes() {
        return {
            data: PropTypes.object.isRequired,
        }
    }

    constructor(props) {
        super(props);

        this.mounted = false;
        this.state = {
            index: 0,
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.flkty.on('change', index => {
            if (!this.mounted) return;
            this.setState({ index });
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    prev = () => {
        this.flkty.previous();
    }

    next = () => {
        this.flkty.next();
    }

    select = index => {
        this.flkty.select(index);
    }

    render() {

        const { index } = this.state;
        const { data } = this.props;

        return (
            <div className="HeroCarousel">
                <div className="carousel-wrapper">
                    <Flickity
                        options={{
                            autoPlay: 5000,
                            wrapAround: true,
                        }}
                        flickityRef={ f => this.flkty = f }
                    >
                        {
                            data.slides.map((slide, key) => (
                                <Slide { ...slide } key={ key } />
                            ))
                        }
                    </Flickity>
                    <div className="arrows">
                        <div className="arrow" onClick={ this.prev }><img src="/images/left-arrow.svg" alt="Prev" /></div>
                        <div className="arrow" onClick={ this.next }><img src="/images/right-arrow.svg" alt="Next" /></div>
                    </div>
                </div>
                <div className="titles">
                    {
                        data.slides.map((slide, key) => (
                            <h5 key={ key } onClick={ () => this.select(key) } className={ `${ index === key && 'is-selected' }` }>{ slide.slide.name }</h5>
                        ))
                    }
                </div>
            </div>
        );

    }

}

export default HeroCarousel;
