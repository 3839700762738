// Modules.
import { connect } from 'react-redux';

// Components.
import FooterSocialMenu from './FooterSocialMenu';

// Store.
const mapStateToProps = state => ({
    socialMedias: state.menu.socialMedias,
});

export default connect(mapStateToProps)(FooterSocialMenu);
