import React, { useState } from 'react';

// Components.
import DangerousCopy from '../DangerousCopy';

// Styles.
import './ListItOutItem.scss';

const ListItOutItem = ({ listItem }) => {

    const [ collapsed, setCollapsed ] = useState(true);
    
    return (
        <div className="listItOutItem">                                     
            <h6 onClick={(e) => {e.preventDefault(); setCollapsed(!collapsed)}}>{ listItem.list_item_header } 
                <span className={collapsed ? 'down' : 'up'}>
                    <svg viewBox="0 0 11 15" fill="none">
                        <path d="M10.328 5.418a.974.974 0 00-1.377 0L6.197 8.172a.278.278 0 01-.394 0L3.05 5.418a.974.974 0 00-1.377 0l-.59.59a.278.278 0 000 .394l4.721 4.722a.278.278 0 00.394 0l4.721-4.722a.278.278 0 000-.394l-.59-.59z" fill="#434444" />
                    </svg>
                </span>
            </h6>                
            <div className={collapsed === true ? 'itemCopy collapsed' : 'itemCopy expanded'}>
                <DangerousCopy copy={ listItem.list_item_copy } />
            </div>
        </div>
                    

                   
    );

};

export default ListItOutItem;
