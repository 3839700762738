// Modules.
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components.
import HeaderMobileLogoBar from './elements/HeaderMobileLogoBar';
import HeaderMobileMenu from './elements/HeaderMobileMenu';
import HeaderMobileTagBar from './elements/HeaderMobileTagBar';

// Context.
import { HeaderMobileContext } from '../../context';

// Utils.
import { constructMenuStructure } from '../../utils/menu';

// Utils.
import { hideOverflow } from '../../utils/layout';

// Styles.
import './HeaderMobile.scss';

const HeaderMobile = ({ gvars, primary }) => {

    const [ opened, setOpened ] = useState(false);

    if (!primary) return null;

    const menu = constructMenuStructure(primary.items);

    hideOverflow(opened);

    return (
        <HeaderMobileContext.Provider value={ { opened, setOpened } }>
            <div className="HeaderMobile">
                <HeaderMobileLogoBar
                    opened={ opened }
                    onToggle={ (open = null) => { setOpened(open !== null ? open : !opened); } }
                />
                { gvars.header_tag &&
                    <Link to="/">
                        <HeaderMobileTagBar>{ gvars.header_tag }</HeaderMobileTagBar>
                    </Link>
                }
                { opened && <HeaderMobileMenu data={ menu } /> }
            </div>
        </HeaderMobileContext.Provider>
    );
};

HeaderMobile.propTypes = {
    gvars: PropTypes.object,
    primary: PropTypes.object,
};

HeaderMobile.defaultProps = {
    gvars: {},
    primary: null,
};


export default HeaderMobile;
