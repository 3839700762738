// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Flickity from 'react-flickity-component';

// Components.
import TimelineControls from './elements/TimelineControls';

// Context.
import { StageContext } from '../../context';

// Utils.
import { TABLET_PORTRAIT } from '../../utils/constants';

// Styles.
import './Timeline.scss';

const Slide = ({ data }) => (
    <StageContext.Consumer>
        { ({ width }) => {

            const style = {};
            if (width < TABLET_PORTRAIT && data.image.source) {
                style.backgroundImage = `url(${ data.image.source.url })`
            }

            return (
                <div
                    className={ classnames({ 'Slide': true, 'text-only': !data.image.source }) }
                    style={ style }
                >
                    <div className="wrapper">
                        <div className="content">
                            <h4>{ data.date }</h4>
                            <h5>{ data.header }</h5>

                            <div>{ data.copy }</div>
                        </div>
                        { width >= TABLET_PORTRAIT && data.image.source &&
                            <div className="image">
                                <img src={ data.image.source.url } alt={ data.image.source.title } />
                                <div className="caption">{ data.image.caption }</div>
                            </div>
                        }
                    </div>
                </div>
            );
    } }
    </StageContext.Consumer>
);

class Timeline extends Component {

    static get propTypes() {
        return {
            data: PropTypes.object.isRequired,
        }
    }

    static contextType = StageContext;

    state = {
        index: 0,
    }

    componentDidMount() {
        this.flkty.on('change', index => {
            this.setState({ index })
        });

        setTimeout(() => this.flkty.resize(), 1000);
    }

    prev = () => {
        this.flkty.previous();
    }

    next = () => {
        this.flkty.next();
    }

    select = index => {
        this.flkty.select(index);
    }

    render() {

        const { index } = this.state;
        const { data } = this.props;

        return (
            <div className="Timeline">
                <div className="carousel-wrapper" ref={ el => this.$ref = el }>
                    <Flickity
                        options={{ wrapAround: true }}
                        flickityRef={ f => this.flkty = f }
                    >
                        {
                            data.slides.map((slide, key) => (
                                <Slide data={ slide } key={ key } />
                            ))
                        }
                    </Flickity>

                    { this.context.width >= TABLET_PORTRAIT &&
                        <div className="wrapper">
                            <TimelineControls
                                count={ data.slides.length }
                                current={ index }
                                onClick={ this.select }
                                onNext={ this.next }
                                onPrev={ this.prev }
                            />
                        </div>
                    }
                </div>
            </div>
        );

    }

};

export default Timeline;
