// Modules.
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Utils.
import { WP_QUERY_IDS } from '../../../../utils/constants';
import { convertToRelativeURL } from '../../../../utils/menu';

// Styles.
import './SearchDropdownResults.scss';

// Constants.
const PREVIEW_COUNT = 3;

const SearchDropdownResults = ({ gvars, results }) => {

    if (!results) return null;

    const renderPageResult = (result, key) => {
        return (
            <Link
                to={ convertToRelativeURL(result.link) }
                key={ key }
            >
                <div className="result">
                    <img src="/assets/images/icon_link.svg" alt="Page Icon" />
                    <h5>{ result.title.rendered }</h5>
                </div>
            </Link>
        );
    };

    const renderPdfResult = (result, key) => {
        return (
            <a
                href={ result.source_url }
                key={ key }
                rel="noopener noreferrer"
                target="_blank"
            >
                <div className="result">
                    <img src="/assets/images/icon_pdf.svg" alt="PDF Icon" />
                    <h5>{ result.title.rendered }</h5>
                </div>
            </a>
        );
    };

    const renderSearchResults = () => {
        const shown = results.slice(0, PREVIEW_COUNT);

        return (
            <React.Fragment>
                { shown.map((result, key) => {

                    if (result.media_type === WP_QUERY_IDS.FILE) {
                        if (result.mime_type === 'application/pdf') {
                            return renderPdfResult(result, key);
                        }
                    }

                    if (result.type === WP_QUERY_IDS.PAGE) {
                        return renderPageResult(result, key);
                    }

                    return null;

                }) }
            </React.Fragment>
        );
    };

    return (
        <div className="SearchDropdownResults">
            { results.length === 0 && <div className="h8">No results found.</div> }
            { results.length > 0 &&
                <React.Fragment>
                    { renderSearchResults() }
                    <Link to={ convertToRelativeURL(gvars.search_results_page) }>
                        <div className="see-all">See all results →</div>
                    </Link>
                </React.Fragment>
            }
        </div>
    );

};

SearchDropdownResults.propTypes = {
    gvars: PropTypes.object,
    results: PropTypes.array,
};

SearchDropdownResults.defaultProps = {
    gvars: {},
    result: null,
};

export default SearchDropdownResults;
