// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import classnames from 'classnames';

// Styles.
import './CarouselArrowIcon.scss';

const CarouselArrowIcon = ({ strokeWidth, theme }) => (
    <svg
        className={ classnames({
            'CarouselArrowIcon': true,
            [theme]: theme,
        }) }
        width='67'
        height='49'
        viewBox='0 0 67 49'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g fill='none' fillRule='evenodd'>
            <g transform='translate(-881 1)' stroke='#897A50'>
                <g transform='translate(881)' id='R-Arrow-(hover)'>
                    <g id='Arrow' strokeWidth={ strokeWidth }>
                        <polyline id='Stroke-1' points='41 0.3535 64 23.3535 41 46.3535' />
                        <path d='M0.5,23.3535 L64,23.3535' id='Stroke-3' />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

CarouselArrowIcon.propTypes = {
    strokeWidth: PropTypes.number,
    theme: PropTypes.string,
};

CarouselArrowIcon.defaultProps = {
    strokeWidth: 1,
    theme: "blue",
};

export default CarouselArrowIcon;
