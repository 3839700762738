// Modules.
import { connect } from 'react-redux';

// Components.
import SearchDropdownResults from './SearchDropdownResults';

const mapStateToProps = state => ({
    gvars: state.global.gvars,
});

// Exports.
export default connect(mapStateToProps)(SearchDropdownResults);
