// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import ACFLink from '../ACFLink';
import Button from '../Button';

// Context.
import { StageContext } from '../../context';

// Utils.
import { ACF_LINK_TYPES, TABLET_PORTRAIT } from '../../utils/constants';

// Styles.
import './QuickInfo.scss';

const Block = ({ info }) => {

    const classes = classnames({
        'Info': true,
        'mobile-only': info.is_mobile_bcolor,
        [info.bcolor]: info.bcolor,
    });

    const getButtonTheme = (width) => {
        return info.is_mobile_bcolor
            ? ((width >= TABLET_PORTRAIT) || !info.bcolor || info.bcolor === "cream") ? "gold" : "cream"
            : (!info.bcolor || info.bcolor === "cream") ? "gold" : "cream";
    };

    return (
        <StageContext.Consumer>
            { ({ width }) => (
                <div className={ classes }>
                    <div className="wrapper">
                        <h2>{ info.header }</h2>
                        <p>{ info.copy }</p>
                        { info.link_type !== ACF_LINK_TYPES.NONE &&
                            <ACFLink data={ info }>
                                <Button theme={ getButtonTheme(width) } hollow={ true }>{ info.button_label }</Button>
                            </ACFLink>
                        }
                    </div>
                </div>
            ) }
        </StageContext.Consumer>
    );

};

const QuickInfo = ({ data }) => {

    const classes = classnames({
        'QuickInfo': true,
        'padded': data.has_margins,
    });

    return (
        <div className={ classes }>
            {
                data.quick_info_copies.map((info, key) => (
                    <Block info={ info } key={ key } />
                ))
            }
        </div>
    );

};

QuickInfo.propTypes = {
    data: PropTypes.object.isRequired,
};

export default QuickInfo;
