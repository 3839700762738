// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import HeaderMenuItem from '../HeaderMenuItem';
import LoginMenuItem from '../LoginMenuItem';
import SearchMenuItem from '../SearchMenuItem';

// Styles.
import './HeaderMenu.scss';

const HeaderMenu = ({ collapsed, current, data, gvars }) => (
    <div className="HeaderMenu">
        { data.map(
            (item, key) => (
                <HeaderMenuItem
                    current={ current }
                    item={ item }
                    key={ key }
                    collapsed={ collapsed }
                />
            )
        ) }
        { gvars.login_menu_text && <LoginMenuItem collapsed={ collapsed } /> }
        { gvars.search_menu_text && <SearchMenuItem collapsed={ collapsed } /> }
    </div>
);

HeaderMenu.propTypes = {
    collapsed: PropTypes.bool,
    current: PropTypes.object,
    data: PropTypes.array,
    gvars: PropTypes.object,
};

HeaderMenu.defaultProps = {
    collapsed: false,
    data: [],
    gvars: {},
};

export default HeaderMenu;
