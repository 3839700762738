// Global.
export const STORE_GLOBAL_VARIABLES = 'STORE_GLOBAL_VARIABLES';

// Loading.
export const UPDATE_INIT_LOAD_COUNTER = 'UPDATE_INIT_LOAD_COUNTER';
export const UPDATE_PAGE_LOAD_COUNTER = 'UPDATE_PAGE_LOAD_COUNTER';

// Menu.
export const STORE_ANCHOR_LINKS_DATA = 'STORE_ANCHOR_LINKS_DATA';
export const STORE_FAD_DATA = 'STORE_FAD_DATA';
export const STORE_FOOTER_MENU_DATA = 'STORE_FOOTER_MENU_DATA';
export const STORE_LEGAL_MENU_DATA = 'STORE_LEGAL_MENU_DATA';
export const STORE_PRIMARY_MENU_DATA = 'STORE_PRIMARY_MENU_DATA';
export const STORE_SOCIAL_MENU_DATA = 'STORE_SOCIAL_MENU_DATA';
export const STORE_SOCIAL_MEDIAS_DATA = 'STORE_SOCIAL_MEDIAS_DATA';

// Modal.
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

// Search.
export const EMPTY_SEARCH_RESULTS = 'EMPTY_SEARCH_RESULTS';
export const STORE_SEARCH_RESULTS = 'STORE_SEARCH_RESULTS';
export const STORE_SEARCH_VALUE = 'STORE_SEARCH_VALUE';
