import {
    UPDATE_INIT_LOAD_COUNTER,
    UPDATE_PAGE_LOAD_COUNTER,
} from '../actions/types';

const initialState = {
    init: 0,
    page: 0,
};

export default function appReducer(state = initialState, action) {

    switch (action.type) {

        case UPDATE_INIT_LOAD_COUNTER:
            return {
                ...state,
                init: state.init + action.payload.data,
            };

        case UPDATE_PAGE_LOAD_COUNTER:
            return {
                ...state,
                page: state.page + action.payload.data,
            };

        default:
            return state;

    }

};
