// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Components.
import Button from '../../../Button';

// Styles.
import './FeaturedArticle.scss';

const FeaturedArticle = ({ data }) => (
    <div className="FeaturedArticle">
        <div className="date">{ moment(data.post_date).format("MMMM D, YYYY") }</div>

        <div className="inner-content">
            <h3>{ data.post_title }</h3>
            <p>{ data.post_excerpt.replace(/<.*?>/g, '') }</p>
        </div>

        <div className="footer-content">
            <h4>{ data._embedded.author[0].name }</h4>
            <a href={ data.link } rel="noopener noreferrer" target="_blank">
                <Button theme="red" hollow={ false }>Read more</Button>
            </a>
        </div>
    </div>
);

FeaturedArticle.propTypes = {
    data: PropTypes.object.isRequired,
};

export default FeaturedArticle;
