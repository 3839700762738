// Modules.
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components.
import HeaderMobileMenuItem from '../HeaderMobileMenuItem';
import LoginMobileMenuItem from '../LoginMobileMenuItem';
import SearchMobileMenuItem from '../SearchMobileMenuItem';

// Utils.
import { hideOverflow } from '../../../../utils/layout';

// Styles.
import './HeaderMobileMenu.scss';

const HeaderMobileMenu = ({ data }) => {

    const [ expanded, setExpanded ] = useState(null);

    useEffect(() => {
        return () => {
            hideOverflow(false);
        }
    }, []);

    const handleItemClick = (key) => {
        if (expanded !== null && expanded === key) {
            setExpanded(null);
        } else {
            setExpanded(key);
        }
    };

    return (
        <div className="HeaderMobileMenu">
            { data.map(
                (item, key) => (
                    <HeaderMobileMenuItem
                        key={ key }
                        item={ item }
                        onItemClick={ () => { handleItemClick(key); } }
                        expanded={ expanded === key }
                    />
                )
            ) }

            <LoginMobileMenuItem />
            <SearchMobileMenuItem />

        </div>
    );

};

HeaderMobileMenu.propTypes = {
    data: PropTypes.array,
};

HeaderMobileMenu.defaultProps = {
    data: [],
};

export default HeaderMobileMenu;
