// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles.
import './Radio.scss';

const Radio = ({ onClick, selected, value }) => {

    const classes = classnames({
        'Radio': true,
        'selected': selected,
    });

    return (
        <div className={ classes } onClick={ onClick }>
            {/* Double-titles for hover-state */}
            <span>
                <svg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
                    <circle cx='9' cy='9' r='8' />
                </svg>
                { value }
            </span>
            <span>
                <svg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
                    <circle cx='9' cy='9' r='8' />
                </svg>
                { value }
            </span>
        </div>
    );

};

Radio.propTypes = {
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
};

Radio.defaultProps = {
    onClick: f => f,
    selected: false,
};

export default Radio;
