import {
    STORE_ANCHOR_LINKS_DATA,
    STORE_FAD_DATA,
    STORE_FOOTER_MENU_DATA,
    STORE_LEGAL_MENU_DATA,
    STORE_PRIMARY_MENU_DATA,
    STORE_SEARCH_RESULTS,
    STORE_SOCIAL_MENU_DATA,
    STORE_SOCIAL_MEDIAS_DATA,
} from '../actions/types';

const initialState = {
    anchors: null,
    fad: null,
    footer: null,
    legal: null,
    primary: null,
    searchTerm: null,
    searchResults: null,
    socials: null,
    socialMedias: null,
};

export default function appReducer(state = initialState, action) {

    switch (action.type) {

        case STORE_ANCHOR_LINKS_DATA: {
            return {
                ...state,
                anchors: action.payload.data,
            };
        }

        case STORE_FAD_DATA: {
            return {
                ...state,
                fad: action.payload.data,
            };
        }

        case STORE_FOOTER_MENU_DATA: {
            return {
                ...state,
                footer: action.payload.data,
            };
        }

        case STORE_LEGAL_MENU_DATA: {
            return {
                ...state,
                legal: action.payload.data,
            };
        }

        case STORE_PRIMARY_MENU_DATA: {
            return {
                ...state,
                primary: action.payload.data,
            };
        }

        case STORE_SEARCH_RESULTS: {
            return {
                ...state,
                searchTerm: action.payload.searchTerm,
                searchResults: action.payload.data,
            };
        }

        case STORE_SOCIAL_MENU_DATA: {
            return {
                ...state,
                socials: action.payload.data,
            };
        }

        case STORE_SOCIAL_MEDIAS_DATA: {
            return {
                ...state,
                socialMedias: action.payload.data,
            };
        }

        default:
            return state;

    }

};
