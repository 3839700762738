import {
    STORE_GLOBAL_VARIABLES,
} from '../actions/types';

const initialState = {
    gvars: null,
};

export default function appReducer(state = initialState, action) {

    switch (action.type) {

        case STORE_GLOBAL_VARIABLES: {
            return {
                ...state,
                gvars: action.payload.data,
            };
        }

        default:
            return state;

    }

};
