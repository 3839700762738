// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import MenuLink from '../../../MenuLink';

// Utils.
import { constructMenuStructure } from '../../../../utils/menu';

// Styles.
import './FooterMenu.scss';

const FooterMenu = ({ links, title }) => (
    <div className="FooterMenu">
        <h6>{ title }</h6>
        { constructMenuStructure(links).map((link, key) => (
            <MenuLink data={ link } key={ key }>
                <div dangerouslySetInnerHTML={ { __html: link.title } } />
            </MenuLink>
        )) }
    </div>
);

FooterMenu.propTypes = {
    links: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
};

export default FooterMenu;
