// Modules.
import { connect } from 'react-redux';

// Components.
import App from './App';

// Store.
const mapStateToProps = state => ({
    gvars: state.global.gvars,
    initLoad: state.loading.init,
    pageLoad: state.loading.page,
});

export default connect(mapStateToProps)(App);
