// Modules.
import { connect } from 'react-redux';

// Components.
import HeaderMenu from './HeaderMenu';

// Store.
const mapStateToProps = state => ({
    gvars: state.global.gvars,
});

export default connect(mapStateToProps)(HeaderMenu);
