// Modules.
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Utils.
import { convertToRelativeURL } from '../../utils/menu';

// Constants.
import { ACF_LINK_TYPES, MODAL_TYPES, WP_QUERY_IDS } from '../../utils/constants';

const ACFLink = ({ anchors, children, data, openModal }) => {

    if (!data.link_type) {
        console.warn('[ACFLink] Missing link_type attribute');
        return children;
    }

    switch (data.link_type) {

        case ACF_LINK_TYPES.INTERNAL: {

            if (!data.internal_link) return children;

            // Check if the Internal Link is an Anchor Link
            if (data.internal_link.includes(WP_QUERY_IDS.ANCHOR_LINK)) {
                const anchor = anchors.find(a => a.link === data.internal_link);
                if (!anchor) return children;

                const pathname = convertToRelativeURL(anchor.acf.page);
                return (
                    <Link to={ {
                        pathname: pathname.replace(/\/$/, ''),
                        hash: `#${ anchor.acf.anchor_id }`,
                    } }>
                        { children }
                    </Link>
                );
            }

            // Else, Internal Link is a Page Link
            return (
                <Link to={ convertToRelativeURL(data.internal_link) }>{ children }</Link>
            );

        }

        case ACF_LINK_TYPES.EXTERNAL: {

            if (!data.external_link) return children;

            return (
                <a
                    href={ data.external_link.url }
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    { children }
                </a>
            );

        }

        case ACF_LINK_TYPES.FAD_MODAL:
            return (
                <span onClick={ () => { openModal(MODAL_TYPES.FAD_MODAL); } }>
                    { children }
                </span>
            );

        case ACF_LINK_TYPES.JOTFORM_MODAL:
            return (
                <span onClick={ () => {
                    openModal(
                        MODAL_TYPES.JOTFORM_MODAL,
                        { url: data.external_link.url }
                    )
                } }>
                    { children }
                </span>
            );

        case ACF_LINK_TYPES.VIDEO_MODAL:
            return (
                <span onClick={ () => {
                    openModal(
                        MODAL_TYPES.VIDEO_MODAL,
                        { id: data.youtube_video_id }
                    )
                } }>
                    { children }
                </span>
            );

        case ACF_LINK_TYPES.NONE:
        default:
            return children;

    }

};

ACFLink.propTypes = {
    anchors: PropTypes.array.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
    ]).isRequired,
    data: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
};

export default ACFLink;
