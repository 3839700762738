// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import ACFLink from '../../../ACFLink';
import Button from '../../../Button';
import DangerousCopy from '../../../DangerousCopy/';
import ShowMoreToggle from '../../../ShowMoreToggle/';

// Constants.
import { ACF_LINK_TYPES } from '../../../../utils/constants';

// Styles.
import './ShowMoreBlock.scss';

const ShowMoreBlock = ({ block, hidden, identifier, onToggle }) => (
    <div className="ShowMoreBlock" id={ identifier }>
        <h4>{ block.header }</h4>
        <DangerousCopy copy={ hidden ? `<p>${ block.sample_copy }</p>` : block.full_copy } />

        { !hidden && block.link_type !== ACF_LINK_TYPES.NONE &&
            <ACFLink data={ block }>
                <Button theme="gold" hollow={ true }>{ block.button_label }</Button>
            </ACFLink>
        }

        <ShowMoreToggle onClick={ onToggle } showing={ !hidden } />
    </div>
);

ShowMoreBlock.propTypes = {
    block: PropTypes.object.isRequired,
    hidden: PropTypes.bool,
    identifier: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired,
};

ShowMoreBlock.defaultProps = {
    hidden: true,
};

export default ShowMoreBlock;
