// Modules.
import { connect } from 'react-redux';

// Components.
import MenuLink from './MenuLink';

// Store.
import { openModal } from '../../redux/actions/app';

const mapStateToProps = state => ({
    anchors: state.menu.anchors,
});

const mapDispatchToProps = {
    openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuLink);
