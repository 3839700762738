// Modules.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// import filesize from 'filesize';

// Components.
import ACFLink from '../ACFLink';
import Button from '../Button';
import DangerousCopy from '../DangerousCopy';
import HoverHeader from '../HoverHeader/';
import ShowMoreToggle from '../ShowMoreToggle';

// Constants.
import { ACF_LINK_TYPES } from '../../utils/constants';

// Styles.
import './Downloadables.scss';

const Downloadables = ({ data }) => {

    const [ hidden, setHidden ] = useState(true);

    const classes = classnames({
        'Downloadables': true,
        'shadowed': data.is_shadowed,
        'full-width': data.is_full_width,
    });

    const getDownloadableLabel = (downloadables) => {
        const title = downloadables.file.title;
        // const size = filesize(downloadables.file.filesize);

        let type = '';
        switch (downloadables.file.mime_type) {
            case 'application/pdf':
                type = ' PDF';
                break;
            default:
                break;
        }

        return (
            <a href={ downloadables.file.url } target="_blank" rel="noopener noreferrer">
                <HoverHeader>
                    <div className="header-content">{ `${ title } (${ type })` }</div>
                </HoverHeader>
                { downloadables.file.mime_type === 'application/pdf' &&
                    <img src="/assets/images/icon_pdf.svg" alt="PDF Icon" />
                }
            </a>
        );
    };

    return (
        <div className={ classes }>
            <div className="wrapper">
                <h5>{ data.header }</h5>

                <div className="block-content-wrapper">
                    <div className="copy">
                        <DangerousCopy copy={ hidden ? `<p>${ data.sample_copy }</p>` : data.full_copy } />
                        <ShowMoreToggle onClick={ () => { setHidden(!hidden); } } showing={ !hidden } />
                    </div>
                    <div className="files">
                        { data.documents.length > 0 &&
                            <ul>
                                { data.documents.map((document, key) => (
                                    document.file && <li key={ key }>{ getDownloadableLabel(document) }</li>
                                )) }
                            </ul>
                        }

                        { data.link_type !== ACF_LINK_TYPES.NONE &&
                            <ACFLink data={ data }>
                                <Button theme="gold" hollow={ true }>{ data.button_label }</Button>
                            </ACFLink>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

Downloadables.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Downloadables;
