// Modules.
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components.
import ArrowIcon from '../../../ArrowIcon';
import FadExpandMenu from '../FadExpandMenu';
import LinkExpandMenu from '../LinkExpandMenu';

// Context.
import { HeaderMobileContext } from '../../../../context';

// Constants.
import { TABLET_PORTRAIT, WP_QUERY_IDS } from '../../../../utils/constants';

// Styles.
import './HeaderMobileMenuItem.scss';

const HeaderMobileMenuItem = ({ expanded, item, onItemClick }) => {

    const context = useContext(HeaderMobileContext);

    const classes = classnames({
        'HeaderMobileMenuItem': true,
        'expanded': expanded,
    });

    const hasSubmenu = item.__children && item.__children.length > 0;
    const isFad = item.object === WP_QUERY_IDS.FIND_A_DOCTOR;

    const renderLinkRow = () => {
        if (item.object === WP_QUERY_IDS.CUSTOM) {
            return (
                <a
                    href={ item.__pathname }
                    rel="noopener noreferrer"
                    target="_blank">
                    <h5>{ item.title }</h5>
                </a>
            );
        }

        return (
            <Link to={ item.__pathname } onClick={ () => { context.setOpened(false); }}>
                <h5>{ item.title }</h5>
            </Link>
        );
    };

    const renderExpanderRow = () => (
        <h5 onClick={ onItemClick }>
            { item.title }
            <ArrowIcon theme="cream" down={ !expanded } />
        </h5>
    );

    return (
        <div className={ classes } style={ { maxWidth: `${ TABLET_PORTRAIT }px` }}>
            { (hasSubmenu || isFad) ? renderExpanderRow() : renderLinkRow() }

            { isFad && expanded &&
                <FadExpandMenu />
            }

            { hasSubmenu && expanded &&
                <LinkExpandMenu item={ item } />
            }
        </div>
    );

};

HeaderMobileMenuItem.propTypes = {
    expanded: PropTypes.bool,
    item: PropTypes.object.isRequired,
    onItemClick: PropTypes.func.isRequired,
};

HeaderMobileMenuItem.defaultProps = {
    expanded: false,
};

export default HeaderMobileMenuItem;
