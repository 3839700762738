// Modules.
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

// Components.
import ShowMoreBlock from './elements/ShowMoreBlock';

// Constants.
import { TABLET_PORTRAIT } from '../../utils/constants';
import { scrollToAnchor } from '../../utils/scrollTo';

// Context.
import { StageContext } from '../../context';

// Styles.
import './ShowMoreSection.scss';

const ShowMoreSection = ({ data }) => {

    const context = useContext(StageContext);
    const [ hidden, setHidden ] = useState(
        [ ...Array(data.show_more_copies.length) ].fill(
            true, 0, data.show_more_copies.length
        )
    );

    const odds = data.show_more_copies.filter((element, index) => index % 2 === 0);
    const evens = data.show_more_copies.filter((element, index) => index % 2 !== 0);

    const updateHidden = (key, uuid) => {
        const nextHidden = [...hidden];
        nextHidden[key] = !hidden[key];
        setHidden(nextHidden);

        if (!nextHidden[key]) {
            scrollToAnchor(`.ShowMoreBlock#${ uuid } h4`);
        }
    };

    return (
        <div className="ShowMoreSection">
            <div className="wrapper">
                <div className="content">

                    <div className="blocks">
                        { context.width < TABLET_PORTRAIT &&
                            <div className="blocks-column">
                                { data.show_more_copies.map((block, key) => {
                                    const uuid = `smb-${ key }`;
                                    return (
                                        <ShowMoreBlock
                                            key={ key }
                                            block={ block }
                                            identifier={ uuid }
                                            hidden={ hidden[key] }
                                            onToggle={ () => { updateHidden(key, uuid); } }
                                        />
                                    );
                                }) }
                            </div>
                        }

                        { context.width >= TABLET_PORTRAIT &&
                            <React.Fragment>
                                <div className="blocks-column">
                                    { odds.map((block, key) => {
                                        const uuid = `smb-${ key * 2 + 1 }`;
                                        return (
                                            <ShowMoreBlock
                                                key={ key }
                                                block={ block }
                                                identifier={ uuid }
                                                hidden={ hidden[key * 2 + 1] }
                                                onToggle={ () => { updateHidden(key * 2 + 1, uuid); } }
                                            />
                                        );
                                    }) }
                                </div>

                                <div className="blocks-column">
                                    { evens.map((block, key) => {
                                        const uuid = `smb-${ key * 2 }`;
                                        return (
                                            <ShowMoreBlock
                                                key={ key }
                                                block={ block }
                                                identifier={ uuid }
                                                hidden={ hidden[key * 2] }
                                                onToggle={ () => { updateHidden(key * 2, uuid); } }
                                            />
                                        );
                                    }) }
                                </div>
                            </React.Fragment>
                        }
                    </div>

                </div>
            </div>
        </div>
    );

};

ShowMoreSection.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ShowMoreSection;
